// @flow

import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import type {Element} from "React";

type Props = {
  megaMenuOpen: boolean,
  onNavigate: () => any,
  singleLinks: Array<Object>,
};

class ProductsMegaMenu extends React.Component<Props> {
  render(): Element<"div"> {
    return (
      <div
        className={`nav-mega-menu ${
          this.props.megaMenuOpen ? 'nav-mega-menu--open' : ''
        }`}
      >
        <div className="nav-mega-menu__contents">
          <Grid columns={2} className="nav-mega-menu__single-products">
            <Grid.Column
              as={NavLink}
              to="/products"
              name="products"
              className="nav-mega-menu__item"
              onClick={this.props.onNavigate}
            >
              <div className="nav-mega-menu__link">Products</div>
              <p>Overview of the product suite</p>
            </Grid.Column>
            {this.props.singleLinks.map((link, i) => (
              <Grid.Column
                key={i}
                as={Link}
                to={link.url}
                className="nav-mega-menu__item"
                onClick={this.props.onNavigate}
              >
                <div className="nav-mega-menu__link">{link.title}</div>
                <p>{link.strapline}</p>
              </Grid.Column>
            ))}
          </Grid>
          <div className="nav-mega-menu__suites">
            <div className="nav-mega-menu__item">
              <Link
                to="/suites/live-audiences"
                onClick={this.props.onNavigate}
                className="nav-mega-menu__suite-link"
              >
                <span className="nav-mega-menu__link">Live Audiences</span>
                <p className="nav-mega-menu__suite-strapline">
                  Search, activate and evaluate audiences in real-time
                </p>
              </Link>
              <p>
                Includes:
                <br />
                <Link
                  to="/products/live-audience-search"
                  className="nav-mega-menu__link nav-mega-menu__link--hover-colour"
                  onClick={this.props.onNavigate}
                >
                  Search
                </Link>
                <br />
                <Link
                  to="/products/live-audience-activation"
                  className="nav-mega-menu__link nav-mega-menu__link--hover-colour"
                  onClick={this.props.onNavigate}
                >
                  Activation
                </Link>
                <br />
                <Link
                  to="/products/live-audience-evaluation"
                  className="nav-mega-menu__link nav-mega-menu__link--hover-colour"
                  onClick={this.props.onNavigate}
                >
                  Evaluation
                </Link>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsMegaMenu;
