// @flow
import * as React from 'react';
import sizeMe from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import ResourceTile from './ResourceTile';
import type { Resource, ResourceType } from '../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  types: Array<ResourceType>,
  selectedType: ?ResourceType,
  items: Array<Resource>,
  size?: {
    width?: number,
  },
  isFetching?: boolean,
  changeActiveFilter: (t: ResourceType) => void,
  clearSelection: () => void,
};

class ContentTypeFilter extends React.Component<Props> {
  updateLayoutInterval;
  grid: any;

  componentDidMount() {
    this.updateLayoutInterval = setInterval(() => {
      if (this.grid) {
        // $FlowFixMe
        this.grid.updateLayout();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.updateLayoutInterval);
  }

  render() {
    const {
      types,
      selectedType,
      items,
      size,
      isFetching,
      ...props
    } = this.props;

    return (
      <div>
        <div className="content-filter">
          <h4>Filter by Type</h4>
          {selectedType && (
            <button
              onClick={props.clearSelection}
              onKeyPress={props.clearSelection}
              tabIndex={0}
              className="content-filter__clear"
            >
              Clear Selection
            </button>
          )}
          <div className="content-filter__items">
            {types.map((type, i) => {
              if (type.count > 0) {
                return (
                  <button
                    className={`content-filter__item content-filter__item--${
                      type.slug
                    } ${
                      selectedType && selectedType.id === type.id
                        ? 'content-filter__item--active'
                        : ''
                    }`}
                    key={i}
                    onClick={e => props.changeActiveFilter(type)}
                  >
                    {ReactHtmlParser(type.name)}
                  </button>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div
          className={`content-filter-grid ${
            isFetching ? 'content-filter-grid--is-fetching' : ''
          }`}
        >
          <StackGrid
            columnWidth={
              size && size.width && size.width < 768 ? '100%' : '50%'
            }
            gutterWidth={size && size.width && size.width < 768 ? 0 : 30}
            gutterHeight={size && size.width && size.width < 768 ? 20 : 30}
            gridRef={grid => (this.grid = grid)}
          >
            {items &&
              items.map((item, i) => (
                // $FlowFixMe
                <ResourceTile
                  key={i}
                  resourceFormatSlug={
                    item.acf && item.acf.resourceFormat
                      ? item.acf.resourceFormat.slug
                      : undefined
                  }
                  title={item.title.rendered}
                  excerpt={item.excerpt.rendered}
                  slug={item.slug}
                />
              ))}
          </StackGrid>
        </div>
      </div>
    );
  }
}

export default (sizeMe()(ContentTypeFilter): any);
