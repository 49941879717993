// @flow strict
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContactFormRow from '../../components/PageContent/ContactFormRow';
import { sendContact } from '../../actions';
import type { Dispatch } from '../../types';

const mapStateToProps = state => {
  return {
    successMessage: state.settings.genericContactFormSuccessMessage,
    successLink: state.settings.genericContactFormSuccessLink,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      sendContact,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormRow): any);
