// @flow
import type {Node} from "React";import React from 'react';
import { Menu, Responsive } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { getWidth } from '../../helpers';

type Props = {
  loginLinksMegaMenuOpen: boolean,
  onLoginLinksClick: Function,
};

function LoginNavItem({ loginLinksMegaMenuOpen, onLoginLinksClick }: Props): Node {
  return (
    <>
      <Responsive as={React.Fragment} minWidth={768} getWidth={getWidth}>
        <Menu.Item
          as={NavLink}
          to="/login-links"
          name="Login"
          className={`nav-menu__nav-link ${
            loginLinksMegaMenuOpen
              ? 'nav-menu__nav-link--active nav-menu__nav-link--mega-active'
              : ''
          }`}
          activeClassName="nav-menu__nav-link--active"
          onClick={onLoginLinksClick}
        >
          Login
          <span className="nav-menu__dropdown-icon" />
          <span className="nav-menu__dropdown-icon nav-menu__dropdown-icon--active" />
        </Menu.Item>
      </Responsive>
    </>
  );
}

export default LoginNavItem;
