// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Grid, Container } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import ContactForm from '../ContactForm';
import type {
  ContactFormRow as PageContentType,
  SendContact,
  Link as LinkType,
} from '../../types';

type Props = {
  row: PageContentType,
  sendContact: SendContact,
  successMessage: ?string,
  successLink: ?LinkType,
};

type State = {
  success: boolean,
  error: boolean,
  submitted: boolean,
};

class ContactFormRow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      submitted: false,
    };
  }

  onSubmit: (values: any) => Promise<void> = async (values: Object) => {
    try {
      this.setState({
        submitted: true,
      });
      const result = await this.props.sendContact(
        values,
        'Live Contact Form',
        'Contact Form',
        window.location.href
      );
      this.setState({
        success: result,
        error: !result,
      });
    } catch (error) {
      Sentry.captureException(error);
      this.setState({
        success: false,
        error: true,
      });
    }
  };

  render(): React.Element<'div'> {
    const {
      row: { text },
      successLink,
      successMessage,
    } = this.props;
    const { submitted, error } = this.state;
    return (
      <div className="page-row page-row--background-athens-grey">
        <Container>
          <Grid centered stackable>
            <Grid.Column tablet={12} computer={8}>
              <Grid centered>
                <Grid.Column tablet={10} computer={12}>
                  <div className="contact-form__intro">
                    {!submitted || (submitted && error)
                      ? ReactHtmlParser(text)
                      : null}
                  </div>
                </Grid.Column>
              </Grid>
              <ContactForm
                onSubmit={this.onSubmit}
                success={this.state.success}
                error={this.state.error}
                submitted={this.state.submitted}
                successLink={successLink}
                successMessage={successMessage}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default ContactFormRow;
