// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPersonas } from '../actions/personas';
import { loadMarketingPage } from '../actions/readMarketingPage';
import { getPersonasSortedAlphabetically } from '../reducers/personas';
import { fetchProducts } from '../actions';
import PageLoader from '../components/PageLoader';
import CommunityTile from '../components/CommunityTile';
import PersonaListingHeader from '../components/PersonaListingHeader';

type Props = {
  personas: Array<Object>,
  match: {
    params: {
      helixPersonas: string,
      community_slug: string,
    },
  },
  fetchProducts: () => Promise<void>,
  fetchPersonas: (helixCountry: string) => Promise<void>,
  loadMarketingPage: (slug: string) => Promise<void>,
  products: Array<Object>,
  isFetchingMarketingPage: boolean,
  isFetchingPersonas: boolean,
  marketingPage: Object,
  community: Object,
  location: Object,
};

class PersonaListing extends React.Component<Props> {
  componentDidMount() {
    let countryFromUrl = this.props.match.params.helixPersonas;

    this.props.fetchPersonas(
      `${
        countryFromUrl === 'helix-personas-nz'
          ? 'helix_nz'
          : countryFromUrl === 'helix-personas-id'
          ? 'helix_id'
          : 'helix'
      }`
    );

    if (countryFromUrl === 'helix-personas-nz') {
      this.props.loadMarketingPage('nz-persona-listing');
    } else if (countryFromUrl === 'helix-personas-id') {
      this.props.loadMarketingPage('id-persona-listing');
    } else {
      this.props.loadMarketingPage('persona-listing');
    }
    window.scrollTo(0, 0);
  }

  render() {
    const headerRow = this.props.marketingPage?.acf?.pageContent.find(
      (header) => {
        return header.acfFcLayout === 'persona_listing_header';
      }
    );

    const { personas } = this.props;

    if (!personas || personas.length === 0) {
      return <PageLoader />;
    }

    const personaTiles = personas
      ? personas.map((persona, key) => {
          if (persona && persona.acf) {
            return (
              <CommunityTile
                key={key}
                colour={persona.acf.color}
                image={persona.acf.featuredImage.url}
                title={persona.postTitle}
                summary={persona.acf.listingSummary}
                communityImageGallery={persona.acf.communityImageGallery}
                personas={persona.personas}
                acf={persona.acf}
                country={
                  this?.props?.location?.pathname.includes('-nz')
                    ? 'nz'
                    : this?.props?.location?.pathname.includes('-id')
                    ? 'id'
                    : 'au'
                }
                replaceWithBreaks={true}
                communitySlug=""
                communities={{}}
                clearSlug={false}
              />
            );
          } else {
            return '';
          }
        })
      : [];

    return (
      <div className="page-container">
        <PersonaListingHeader row={headerRow}></PersonaListingHeader>
        <div className="page-row">
          <div className="ui container"></div>
        </div>
        <div className="page-row">
          <div className="ui container">
            <div className="community-tiles">
              <div className="community-tiles-flex">{personaTiles}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personas: getPersonasSortedAlphabetically(state),
    marketingPage:
      state.marketingPages.pages['persona-listing'] ||
      state.marketingPages.pages['id-persona-listing'] ||
      state.marketingPages.pages['nz-persona-listing'],
    isFetchingMarketingPage: state.marketingPages.isFetching,
    isFetchingPersonas: state.personas.isFetching,
    products: state.products.items,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPersonas,
      loadMarketingPage,
      fetchProducts,
    },
    dispatch
  );

export default (connect<Props, {}, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PersonaListing): any);
