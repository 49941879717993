// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'semantic-ui-react';
import { fetchIndoPersonas } from '../../actions';
import CommunityTile from '../CommunityTile';
import { getIndoPersonasSortedAlphabetically } from '../../reducers/indo_personas';
import type { Dispatch } from '../../types';
import PageLoader from '../PageLoader';

type Props = {
  fetchIndoPersonas: () => Promise<*>,
  indo_personas: Object,
  clearSlug: Function,
  persona_slug?: string,
};

class HelixIndoPersonaList extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchIndoPersonas();
  }

  render() {
    const { indo_personas, persona_slug, clearSlug } = this.props;

    if (!indo_personas || indo_personas.length === 0) {
      return <PageLoader />;
    }

    const personaTiles = indo_personas
      ? indo_personas.map((persona, key) => {
          if (persona && persona.acf) {
            return (
              <CommunityTile
                communities={indo_personas}
                key={key}
                colour={persona.acf.color}
                image={persona.acf.featuredImage.url}
                title={persona.postTitle}
                summary={persona.acf.listingSummary}
                communityImageGallery={persona.acf.communityImageGallery}
                personas={persona.personas}
                persona_slug={persona_slug}
                clearSlug={clearSlug}
                acf={persona.acf}
                buttonLinkOverride={`/products/helix-personas-id/${
                  persona.postName
                }-${persona.acf.title.replace(/\s+/g, '-').toLowerCase()}`}
                personaSlugOverride="helix-personas-id"
                replaceWithBreaks={true}
                country="id"
                communitySlug=''
              />
            );
          } else {
            return '';
          }
        })
      : [];

    return (
      <div className="page-row">
        <Container>
        <h3 className="helix-persona-list__heading">Browse Helix Personas</h3>
          <div className="community-tiles">
            <div className="community-tiles-flex">{personaTiles}</div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    indo_personas: getIndoPersonasSortedAlphabetically(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchIndoPersonas,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(HelixIndoPersonaList): any);
