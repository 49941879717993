// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import ReactHtmlParser from 'html-react-parser';
import { Container, Grid, Button } from 'semantic-ui-react';
import { camelizeKeys } from 'humps';
import Link from './Link';
import ResourceTile from './ResourceTile';
import { createExcerptIfNotDefined, noCamelize } from '../helpers';
import type { ResourceExcerpt } from '../types';
import { API_BASE } from '../helpers';

type Props = {
  title?: ?string,
  resources: [
    {
      resource: ResourceExcerpt,
    },
  ],
};

type State = {
  resources: [
    {
      resource: ResourceExcerpt,
    },
  ],
};

class MoreResources extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      resources: this.props.resources,
    };
  }

  componentDidMount() {
    // Retrieve each featured resource's type via API, then store in local state.
    // TODO: Most of this code is copy and pasted from FeaturedResources. Refactor the following section so that both files can share the same code.
    const { resources } = this.props;
    if (typeof resources !== 'boolean') {
      resources.map((resourceObject, i) => {
        const { resource } = resourceObject;

        fetch(`${API_BASE}/wp-json/acf/v3/posts/${resource.id}/resource_format`)
          .then(
            response => response.json(),
            error => {
              Sentry.captureException(error);
              // eslint-disable-next-line no-console
              console.error('An error occured.', error);
            }
          )
          .then(json => {
            const camelizedJson = camelizeKeys(json, noCamelize);
            const workingArray = this.state.resources;
            if (
              typeof workingArray !== 'boolean' &&
              typeof workingArray[i].resource !== 'boolean'
            ) {
              workingArray[i].resource.acf = {
                resourceFormat: camelizedJson.resourceFormat,
              };
            }

            this.setState({
              resources: workingArray,
            });
          });

        return true;
      });
    }
  }

  render(): React.Element<"div"> | string {
    const { title = 'More Resources' } = this.props;
    const { resources } = this.state;

    if (!resources) {
      return '';
    }

    return (
      <div className="page-row ">
        <Container>
          <h2 className="page-row__title">{ReactHtmlParser(title)}</h2>
          <Grid columns={2} stackable centered stretched>
            {resources.map((resourceObject, i) => {
              const { resource } = resourceObject;

              return (
                <Grid.Column key={`more-resources-${resource.id}`}>
                  <ResourceTile
                    title={resource.postTitle}
                    excerpt={createExcerptIfNotDefined(
                      resource.postExcerpt,
                      resource.postContent
                    )}
                    slug={resource.postName}
                    resourceFormatSlug={
                      resource.acf && resource.acf.resourceFormat
                        ? resource.acf.resourceFormat.slug
                        : undefined
                    }
                    smallTitle
                  />
                </Grid.Column>
              );
            })}
          </Grid>
          <div className="page-row__buttons">
            <Button as={Link} inverted url="/resources">
              View All Resources
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default MoreResources;
