// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Head from '../components/Head';
import Article from '../components/Resource/Article';
import Video from '../components/Resource/Video';
import Report from '../components/Resource/Report';
import SuccessStory from '../components/Resource/SuccessStory';
import { fetchResource, fetchResourceTypes } from '../actions';
import PageLoader from '../components/PageLoader';
import PageTitle from '../components/PageTitle';
import { findBySlug } from '../helpers';
import type { Resource as ResourceSingle, Resources, Dispatch } from '../types';

type Props = {
  match: {
    params: {
      slug: string,
    },
  },
  resources: Resources,
  isFetching: boolean,
  fetchResource: (s: string) => Promise<void>,
  fetchResourceTypes: () => Promise<void>,
};

type State = {
  currentResource: ?ResourceSingle,
};

class Resource extends React.Component<Props, State> {
  componentDidMount() {
    const { fetchResource, match, fetchResourceTypes, resources } = this.props;
    const slug = match.params.slug;

    const currentResource = findBySlug(resources.items, slug);

    if (!currentResource) {
      fetchResource(slug);
    }
    if (!resources.types) {
      fetchResourceTypes();
    }
  }

  render() {
    const { resources, match } = this.props;
    const slug = match.params.slug;

    // $FlowFixMe disabled for now
    const currentResource: ResourceSingle = findBySlug(resources.items, slug);

    const resourceTypes = resources.types;
    let currentResourceType;
    if (currentResource && currentResource.resourceFormat.length > 0) {
      let currentResourceTypeId = currentResource.resourceFormat[0];
      if (resourceTypes) {
        currentResourceType = resourceTypes.find(type => {
          return type.id === currentResourceTypeId;
        });
      }
    }

    let resourceComponent;
    let pageTitle;
    let pageTitleIcon;

    if (currentResourceType && currentResource) {
      switch (currentResourceType.slug) {
        case 'articles':
          resourceComponent = <Article resource={currentResource} />;
          pageTitle = 'Article';
          pageTitleIcon = 'article';
          break;
        case 'videos':
          resourceComponent = <Video resource={currentResource} />;
          pageTitle = 'Video';
          pageTitleIcon = 'video';
          break;
        case 'press-releases':
          // Press releases use the same template as Articles
          resourceComponent = <Article resource={currentResource} />;
          pageTitle = 'Press Release';
          pageTitleIcon = 'press-release';
          break;
        case 'reports':
          resourceComponent = <Report resource={currentResource} />;
          pageTitle = 'Report';
          pageTitleIcon = 'report';
          break;
        case 'success-stories':
          resourceComponent = <SuccessStory resource={currentResource} />;
          pageTitle = 'Success Story';
          pageTitleIcon = 'success-story';
          break;
        default:
          resourceComponent = (
            <h1>Error unknown Resource format {currentResourceType.slug}</h1>
          );
      }

      const { yoast } = currentResource;

      yoast.title = yoast.title
        ? yoast.title
        : `${currentResource.title.rendered} | Roy Morgan Live`;

      return (
        <div>
          <Head metaTags={currentResource.yoast} />
          <PageTitle title={pageTitle} resourceType={pageTitleIcon} />
          {resourceComponent}
        </div>
      );
    }
    return <PageLoader />;
  }
}

const mapStateToProps = state => {
  const resources = state.resources;
  return {
    resources,
    isFetching: resources.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchResource,
      fetchResourceTypes,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Resource): any);
