// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Button } from 'semantic-ui-react';
import Link from './Link';

type Props = {
  resourceFormatSlug?: string,
  title: string,
  excerpt: string,
  slug: string,
  className?: string,
  smallTitle?: boolean,
};

const ResourceTile = ({
  resourceFormatSlug,
  title,
  excerpt,
  slug,
  className,
  smallTitle = false,
}: Props): React.Element<"div"> => (
  <div className={`callout resource-tile ${className ? className : ''}`}>
    {resourceFormatSlug && (
      <Link
        url={`/resources/${slug}`}
        className={`callout__icon callout__icon--${resourceFormatSlug}`}
      />
    )}
    <Link url={`/resources/${slug}`} className="callout__title">
      {smallTitle ? (
        <h3>{ReactHtmlParser(title)}</h3>
      ) : (
        <h2 className="h2--tile">{ReactHtmlParser(title)}</h2>
      )}
    </Link>
    <div className="callout__content">{ReactHtmlParser(excerpt)}</div>
    {/* <div className="product-col__product-button-container"> */}
    <Button
      as={Link}
      url={`/resources/${slug}`}
      className="callout__read-more button--icon-triangle"
    >
      Read More
    </Button>
  </div>
);

export default ResourceTile;
