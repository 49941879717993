// @flow
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { isUrlAbsolute } from '../helpers';

type Props = {
  /** This is either the relative or absolute URL */
  url: string,
  /** Elements to be displayed inside the link */
  children?: React.Node,
};

class Link extends React.PureComponent<Props> {
  render(): React.Node | React.Element<"a"> {
    const { url, children, ...rest } = this.props;

    if (isUrlAbsolute(url)) {
      return (
        <a {...rest} href={url} data-testid="link-absolute">
          {children}
        </a>
      );
    }
    return (
      <ReactRouterLink {...rest} to={url} data-testid="link-relative">
        {children}
      </ReactRouterLink>
    );
  }
}

export default Link;
