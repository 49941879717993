// @flow
import type { StaticCommunity } from '../types/communities';
import au100 from '../assets/images/personas/au/100.jpg';
import au200 from '../assets/images/personas/au/200.jpg';
import au300 from '../assets/images/personas/au/300.jpg';
import au400 from '../assets/images/personas/au/400.jpg';
import au500 from '../assets/images/personas/au/500.jpg';
import au600 from '../assets/images/personas/au/600.jpg';

import nz100 from '../assets/images/personas/nz/100.png';
import nz200 from '../assets/images/personas/nz/200.png';
import nz300 from '../assets/images/personas/nz/300.png';
import nz400 from '../assets/images/personas/nz/400.png';
import nz500 from '../assets/images/personas/nz/500.png';
import nz600 from '../assets/images/personas/nz/600.png';

// $FlowFixMe
import id100 from '../assets/images/personas/id/100.jpeg';
// $FlowFixMe
import id200 from '../assets/images/personas/id/200.jpeg';
// $FlowFixMe
import id300 from '../assets/images/personas/id/300.jpeg';
// $FlowFixMe
import id400 from '../assets/images/personas/id/400.jpeg';
// $FlowFixMe
import id500 from '../assets/images/personas/id/500.jpeg';
// $FlowFixMe
import id700 from '../assets/images/personas/id/700.jpeg';
import { isIndoHelix, isNZHelix } from './products';

const countryCommunities = {
  au: [
    {
      id: '100',
      name: 'Leading Lifestyles',
      summary:
        'Focused on success and career and family, people in the Leading Lifestyles Community are proud of their prosperity and achievements. They are big spenders and enjoy cultured living to the max.',
      img: au100,
      colour: '#0031BF',
    },
    {
      id: '200',
      name: 'Metrotechs',
      summary:
        'Socially aware, successful, career focussed and culturally diverse, Metrotechs are trend and tech focused. They are committed experience seekers, willing to spend big on the best of city life and thrive on being out and about in the world.',
      img: au200,
      colour: '#00ADDC',
    },
    {
      id: '300',
      name: 'Aspirationals',
      summary:
        'Driven by dreams of a big future, Aspirationals are highly ambitious and culturally diverse up-and-comers. Careful spenders, they’re working hard today to create a more successful tomorrow.',
      img: au300,
      colour: '#008E7F',
    },
    {
      id: '400',
      name: 'Hearth and Home',
      summary:
        'Closest to the average Australian, life revolves around the home for these contented Australians who embrace conventional family life. Perennial home improvers, they see their homes as an expression of their status and achievements.',
      img: au400,
      colour: '#B72267',
    },
    {
      id: '500',
      name: 'Doing Fine',
      summary:
        'Modest but contented, people in the Doing Fine Community are happily making their way through life and value simple pleasures. Price sensitive and light spenders, they take a pragmatic approach to what they buy.',
      img: au500,
      colour: '#F2A00A',
    },
    {
      id: '600',
      name: 'Fair Go',
      summary:
        'Struggling to make ends meet, looking for a better deal in life, making the best of things or simply pessimistic, cynical and likely to feel they get a raw deal out of life; the Fair Go community are lower income Australians.',
      img: au600,
      colour: '#5A5758',
    },
  ],
  nz: [
    {
      id: '100',
      name: 'Leading Lifestyles',
      summary:
        'Focused on success and career and family, people in the Leading Lifestyles Community are proud of their prosperity and achievements. They are big spenders and enjoy cultured living to the max.',
      img: nz100,
      colour: '#0031BF',
    },
    {
      id: '200',
      name: 'Metrotechs',
      summary:
        'Socially aware, successful, career focussed and culturally diverse, Metrotechs are trend and tech focused. They are committed experience seekers, willing to spend big on the best of city life and thrive on being out and about in the world.',
      img: nz200,
      colour: '#00ADDC',
    },
    {
      id: '300',
      name: 'Aspirationals',
      summary:
        'Driven by dreams of a big future, Aspirationals are highly ambitious and culturally diverse up-and-comers. Careful spenders, they’re working hard today to create a more successful tomorrow.',
      img: nz300,
      colour: '#008E7F',
    },
    {
      id: '400',
      name: 'Hearth and Home',
      summary:
        'Closest to the average New Zealander, life revolves around the home for these contented Kiwis who embrace conventional family life. Perennial home improvers, they see their homes as an expression of their status and achievements.',
      img: nz400,
      colour: '#B72267',
    },
    {
      id: '500',
      name: 'Doing Fine',
      summary:
        'Modest but contented, people in the Doing Fine Community are happily making their way through life and value simple pleasures. Price sensitive and light spenders, they take a pragmatic approach to what they buy.',
      img: nz500,
      colour: '#F2A00A',
    },
    {
      id: '600',
      name: 'Fair Go',
      summary:
        'Struggling to make ends meet, looking for a better deal in life, making the best of things or simply pessimistic, cynical and likely to feel they get a raw deal out of life; the Fair Go community are lower income New Zealanders.',
      img: nz600,
      colour: '#5A5758',
    },
  ],
  id: [
    {
      id: '100',
      name: 'Leading Lifestyles',
      summary:
        'High-earning, professionals, technology adopters and fashion conscious families in urban areas.',
      img: id100,
      colour: '#1e73be',
    },
    {
      id: '200',
      name: 'Metrotechs',
      summary:
        'Highly educated, young, optimistic, socially progressive and tech-savvy urban dwellers.',
      img: id200,
      colour: '#22d5e2',
    },
    {
      id: '300',
      name: "Today's Families",
      summary:
        'Educated, young families with above average incomes living in urban areas.',
      img: id300,
      colour: '#45a86d',
    },
    {
      id: '400',
      name: 'Indonesian Achievers',
      summary:
        'A mix of students and families. Attracted to new things and aspiring to improve their lives.',
      img: id400,
      colour: '#aa1e56',
    },
    {
      id: '500',
      name: 'Getting By',
      summary:
        'Traditional and more conservative families who are struggling to make ends meet.',
      img: id500,
      colour: '#dd8f1a',
    },
    {
      id: '700',
      name: 'Rural Lifestyle',
      summary:
        'Rural Indonesians covering a diverse range of incomes, attitudes and values.',
      img: id700,
      colour: '#1a5626',
    },
  ],
};

export const getStaticPersonas = (): StaticCommunity[] => {
  if (isNZHelix()) {
    return countryCommunities.nz;
  } else if (isIndoHelix()) {
    return countryCommunities.id;
  } else {
    return countryCommunities.au;
  }
};
