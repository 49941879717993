// @flow
import * as React from 'react';
import type { Persona } from '../types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPersonas } from '../actions/personas';
import { loadMarketingPage } from '../actions/readMarketingPage';
import { getPersonasSortedAlphabetically } from '../reducers/personas';
import { fetchProducts } from '../actions';
import { findBySlug, isNZHelix, isIndoHelix } from '../helpers';
import PersonaLanding from '../components/persona/PersonaLanding';
import { Redirect } from 'react-router-dom';
import PageLoader from '../components/PageLoader';

type Props = {
  personas: Array<Persona>,
  match: {
    params: {
      helixPersonas: string,
      community_slug: string,
    },
  },
  fetchProducts: () => Promise<void>,
  fetchPersonas: (helixCountry: string) => Promise<void>,
  loadMarketingPage: (slug: string) => Promise<void>,
  products: Array<Object>,
  isFetchingMarketingPage: boolean,
  isFetchingPersonas: boolean,
  marketingPage: Object,
  community: Object,
  location: Object,
};

class Community extends React.Component<Props> {
  componentDidMount() {
    let countryFromUrl = this.props.match.params.helixPersonas;

    this.props.fetchPersonas(
      countryFromUrl === 'helix-personas-nz'
        ? 'helix_nz'
        : countryFromUrl === 'helix-personas-id'
        ? 'helix_id'
        : 'helix'
    );
    if (isNZHelix()) {
      this.props.loadMarketingPage('nz-community-single');
    } else if (isIndoHelix()) {
      this.props.loadMarketingPage('id-community-single');
    } else {
      this.props.loadMarketingPage('community-single');
    }
    this.props.fetchProducts();
    window.scrollTo(0, 0);
  }

  render() {
    const { products } = this.props;
    const slug = 'helix-personas';

    let currentProduct;
    if (products) {
      // $FlowFixMe
      currentProduct = (findBySlug(products, slug): ProductType);
    }
    const personasUrl = this.props.match.params.community_slug;
    const personas = this.props.personas;
    const community = personas.find(
      (persona) => persona.postName === personasUrl.substring(0, 3)
    );

    if (
      this.props.isFetchingPersonas ||
      this.props.isFetchingMarketingPage ||
      !this.props.marketingPage ||
      !currentProduct
    ) {
      return <PageLoader />;
    }

    if (!community) {
      return <Redirect to="/" />;
    }

    return (
      <PersonaLanding
        welcomePage={this.props.marketingPage}
        community={community}
        personas={personas}
        location={this?.props?.location?.pathname}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personas: getPersonasSortedAlphabetically(state),
    marketingPage:
      state.marketingPages.pages['community-single'] ||
      state.marketingPages.pages['id-community-single'] ||
      state.marketingPages.pages['nz-community-single'],
    isFetchingMarketingPage: state.marketingPages.isFetching,
    isFetchingPersonas: state.personas.isFetching,
    products: state.products.items,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPersonas,
      loadMarketingPage,
      fetchProducts,
    },
    dispatch
  );

export default (connect<Props, {}, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Community): any);
