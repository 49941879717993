// @flow
import {
  REQUEST_SUITES,
  RECEIVE_SUITES,
  REQUEST_SUITE,
  RECEIVE_SUITE,
} from '../actions';
import type { SuiteState as State, SuiteAction as Action } from '../types';

const initialState = {
  isFetching: false,
  items: [],
};

function suites(state: State = initialState, action: Action): State {
  switch (action.type) {
    case REQUEST_SUITES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_SUITES:
      return {
        ...state,
        isFetching: false,
        items: action.suites,
      };
    case REQUEST_SUITE:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_SUITE:
      return {
        ...state,
        isFetching: false,
        items: [action.suite],
      };
    default:
      return state;
  }
}

export default suites;
