// @flow
import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from 'roy-morgan-footer';

import ScrollToTopOnNav from '../components/ScrollToTopOnNav';
import NavMenu from '../components/NavMenu';
import BackToTop from '../components/BackToTop';

import Home from './Home';
import Products from './Products';
import Suites from './Suites';
import Product from './Product';
import Bundle from './Bundle';
import Suite from './Suite';
import Resources from './Resources';
import Resource from './Resource';
import ContactUs from './ContactUs';
import Partners from './Partners';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import Media from './Media';

import PersonaListing from './PersonaListing';
import Persona from './Persona';
import Community from './Community';
import type { Store } from '../types';

type Props = {
  store: Store,
};

const Root = ({ store }: Props): React.Node => (
  <Provider store={store}>
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roy Morgan Live</title>
        <meta name="description" content="Roy Morgan Live" />
        <meta property="og:title" content="Roy Morgan Live" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Roy Morgan Live" />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content="Roy Morgan Live" />
        <meta property="twitter:description" content="Roy Morgan Live" />
      </Helmet>
      <Route path="/" component={NavMenu} />
      <div className="page-container">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/products/:helixPersonas/personas/:community_slug/:persona_slug" component={Persona} />
          <Route path="/products/:helixPersonas/personas/:community_slug" component={Community} />
          <Route path="/products/:helixPersonas/personas" component={PersonaListing} />
          <Route path="/suites/:slug" component={Suite} />
          <Route path="/suites" component={Suites} />
          <Route path="/products/premium/:slug" component={Product} />
          <Route path="/products/bundles/:slug" component={Bundle} />
          <Route path="/products/:slug/:persona_slug" component={Product} />
          <Route path="/products/:slug" component={Product} />
          <Route path="/products" component={Products} />
          <Route path="/about" component={About} />
          <Route path="/resources/:slug" component={Resource} />
          <Route path="/resources" component={Resources} />
          <Route path="/partners/:slug" component={Partners} />
          <Route path="/partners" component={Partners} />
          <Route path="/media/:slug" component={Media} />
          <Route path="/media" component={Media} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
      </div>
      <ScrollToTopOnNav />
      <BackToTop scrollStepInPx={50} delayInMs={5} />
      <Footer />
    </React.Fragment>
  </Provider>
);

export default Root;
