// @flow
import * as React from 'react';
import { Grid, Button, Container } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import Link from '../Link';
import type { FeaturedProducts as ContentType } from '../../types';

type Props = {
  row: ContentType,
};

const FeaturedProducts = ({ row }: Props): React.Element<"div"> => {
  const { featuredProductsRepeater, backgroundColour, accentColour } = row;

  let buttonColour;

  accentColour !== 'none' && accentColour !== 'cyan'
    ? (buttonColour = accentColour)
    : (buttonColour = undefined);

  return (
    <div
      className={`page-row page-row--extra-top-padding page-row--background-${backgroundColour}`}
    >
      <Container>
        <Grid centered stackable>
          {typeof featuredProductsRepeater !== 'boolean' &&
            featuredProductsRepeater.map((product, i) => (
              <Grid.Row
                className={
                  i % 2 === 0
                    ? 'tablet reversed computer reversed featured-product'
                    : 'featured-product'
                }
                key={i}
              >
                <Grid.Column tablet={6} computer={4}>
                  <Link
                    url={
                      product.button && product.button.url
                        ? product.button.url
                        : '/'
                    }
                  >
                    <img
                      src={product.image.url}
                      alt={product.image.alt}
                      className="featured-product__image"
                    />
                  </Link>
                </Grid.Column>
                <Grid.Column width={6}>
                  <div className="featured-product__text">
                    <div>
                      <Link
                        url={
                          product.button && product.button.url
                            ? product.button.url
                            : '/'
                        }
                        className="anchor--no-underline"
                      >
                        <h3
                          className={`page-row__title text--${accentColour} featured-product__title`}
                        >
                          {ReactHtmlParser(product.title)}
                        </h3>
                      </Link>
                    </div>
                    <div>{ReactHtmlParser(product.text)}</div>
                    <div className="featured-product__button-container">
                      <Button
                        color={buttonColour}
                        as={Link}
                        url={
                          product.button && product.button.url
                            ? product.button.url
                            : '/'
                        }
                        className="button--icon-triangle"
                      >
                        {ReactHtmlParser(product.button.title)}
                      </Button>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            ))}
        </Grid>
        <div className="page-row__buttons page-row__buttons--extra-top-padding">
          <Button as={Link} url="/products" color={buttonColour} inverted>
            {row.readMoreLinkText}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default FeaturedProducts;
