// @flow
import * as React from 'react';
import LoadSVG from './LoadSVG';

type Props = {
  statImg: string,
  statLabel: string,
  stat: string,
  isNumberOnLeft: boolean,
  colour: string,
  width?: string,
};

const CommunitySummaryTileStat = ({
  statImg,
  statLabel,
  stat,
  isNumberOnLeft,
  colour,
  width,
}: Props): React.Element<typeof React.Fragment> => {
  return (
    <>
      {/*TODO change all classes to community-summary-tile-stat and put in separate SCSS file*/}
      {stat && (
        <div className="community-summary-tile__stats-container">
          <div className="community-summary-tile__stat">
            <span className="community-summary-tile__icon">
              {statImg && (
                <LoadSVG
                  src={statImg}
                  title={statLabel}
                  alt={statLabel}
                  colour={colour}
                  stroke={null}
                  width={width}
                />
              )}
            </span>
            <span className="community-summary-tile__figure">
              {isNumberOnLeft ? stat + ' ' + statLabel : statLabel + ' ' + stat}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default CommunitySummaryTileStat;
