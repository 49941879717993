// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Modal, Image, Button } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { findById } from '../helpers';
import type { Partner as PartnerType, Channel } from '../types';

type Props = {
  partner: PartnerType,
  channels: Array<Channel>,
  slug?: string,
  clearSlug: () => void,
  partnerType: string,
};

class Partner extends React.PureComponent<Props> {
  render() {
    const { partner, channels, slug, clearSlug, partnerType } = this.props;
    return (
      <Modal
        trigger={
          <Button
            as={Link}
            className="partners__partner-button"
            to={`/${partnerType}/${partner.slug}`}
          >
            <Image
              src={partner.acf.partnerLogo.url}
              alt={partner.acf.partnerLogo.alt}
              className="partners__partner-img"
            />
          </Button>
        }
        open={slug === partner.slug}
        closeIcon={<button className="modal__close">Close</button>}
        onClose={clearSlug}
      >
        <Modal.Header>{ReactHtmlParser(partner.title.rendered)}</Modal.Header>
        <Modal.Content>
          <img
            src={partner.acf.partnerLogo.url}
            alt={partner.acf.partnerLogo.alt}
            className="partner__logo"
          />
          {partner.channel && (
            <div className="partner__channels">
              {partner.channel.map((channelId, i) => {
                // $FlowFixMe
                const channelContent: Channel = findById(channels, channelId);
                if (
                  channelContent &&
                  channelContent.acf &&
                  channelContent.acf.topLevel
                ) {
                  return null;
                }
                if (
                  channelContent &&
                  channelContent.acf &&
                  channelContent.acf.channelIcon &&
                  channelContent.acf.channelIcon.url
                ) {
                  return (
                    <div key={`channel-${i}`}>
                      <div
                        className={`callout__icon`}
                        style={{
                          backgroundImage: `url(${channelContent.acf.channelIcon.url})`,
                        }}
                      />
                      <h5 className="partner__channel">
                        {
                          // $FlowFixMe
                          channelContent && ReactHtmlParser(channelContent.name)
                        }
                      </h5>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          <div className="partner__description">
            {ReactHtmlParser(partner.content.rendered)}
          </div>
          <div className="partner__buttons">
            {partner.acf.brochure && (
              <a
                className="ui button button--left-icon-download"
                target="_blank"
                rel="noopener noreferrer"
                href={partner.acf.brochure.url}
              >
                Download brochure
              </a>
            )}
            {partner.acf.websiteLink && (
              <a
                className="ui button inverted"
                target="_blank"
                rel="noopener noreferrer"
                href={partner.acf.websiteLink.url}
              >
                Visit Website
              </a>
            )}
            {partner.acf.contactLink && (
              <a
                className="ui button inverted"
                target="_blank"
                rel="noopener noreferrer"
                href={partner.acf.contactLink.url}
              >
                Contact {partner.title.rendered}
              </a>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default (withRouter(Partner): any);
