// @flow strict
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import pages from './pages';
import bundles from './bundles';
import partners from './partners';
import products from './products';
import resources from './resources';
import settings from './settings';
import suites from './suites';
import { marketingPages } from './marketingPages';
import { personas } from './personas';
import { nz_personas } from './nz_personas';
import { segments } from './segments';
import { indo_personas } from './indo_personas';

const rootReducer: any = combineReducers({
  pages,
  partners,
  products,
  resources,
  settings,
  suites,
  personas,
  nz_personas,
  segments,
  form: formReducer,
  bundles,
  indo_personas,
  marketingPages,
});

export default rootReducer;
