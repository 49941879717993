// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Container } from 'semantic-ui-react';
import PageLoader from '../components/PageLoader';
import Head from '../components/Head';
import { fetchResources, fetchResourceTypes, fetchPage } from '../actions';
import ContentTypeFilter from '../components/ContentTypeFilter';
import PageTitle from '../components/PageTitle';
import type {
  Resource,
  ResourceType,
  FetchResources,
  DefaultPage,
  Dispatch,
} from '../types';

const PAGE_ID = 488;

type Props = {
  resources: Array<Resource>,
  resourceTypes: Array<ResourceType>,
  isFetching?: boolean,
  fetchResources: FetchResources,
  hasMore: boolean,
  page: DefaultPage,
  fetchResourceTypes: () => Promise<void>,
  fetchPage: (i: number) => Promise<void>,
};

type State = {
  selectedResourceType: ?ResourceType,
  currentPage: number,
};

export class Resources extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedResourceType: null,
      currentPage: 1,
    };
  }

  componentDidMount() {
    const { fetchResources, fetchResourceTypes, fetchPage } = this.props;

    fetchResources(this.state.currentPage);
    fetchResourceTypes();
    fetchPage(PAGE_ID);
  }

  changeActiveFilter: ((filter: ResourceType) => void) = (filter: ResourceType) => {
    this.setState({ selectedResourceType: filter, currentPage: 1 });
    this.props.fetchResources(1, filter.id);
  };

  loadMoreResources: (() => void) = () => {
    const nextPage = this.state.currentPage + 1;
    this.setState({
      currentPage: nextPage,
    });
    this.props.fetchResources(
      nextPage,
      this.state.selectedResourceType
        ? this.state.selectedResourceType.id
        : null
    );
  };

  clearSelection: (() => void) = () => {
    this.setState({ selectedResourceType: null });
    this.props.fetchResources();
  };

  render(): React.Element<"div"> | React.Node {
    const { resources, resourceTypes, isFetching, hasMore, page } = this.props;

    if (!page || !page.acf || isFetching) {
      return <PageLoader />;
    }

    return (
      <div>
        <Head metaTags={page.yoast} />
        <PageTitle title="Resources" />
        <Container className="page-content">
          <div className="resource-listing">
            {resourceTypes && resources && (
              <ContentTypeFilter
                items={resources}
                types={resourceTypes}
                selectedType={this.state.selectedResourceType}
                changeActiveFilter={this.changeActiveFilter}
                clearSelection={this.clearSelection}
                isFetching={isFetching}
                data-testid="content-type-filter"
              />
            )}
          </div>
          {resources && resources.length > 0 && hasMore && (
            <div className="text--center">
              <Button onClick={this.loadMoreResources} inverted>
                Load More
              </Button>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const resources = state.resources;
  return {
    page: state.pages[PAGE_ID],
    resources: resources.items,
    resourceTypes: resources.types,
    isFetching: resources.isFetching,
    hasMore: resources.hasMore,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchResources,
      fetchResourceTypes,
      fetchPage,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Resources): any);
