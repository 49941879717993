// @flow
import * as React from 'react';
import { Container, Grid, Form, Checkbox, Dropdown } from 'semantic-ui-react';
import sizeMe from 'react-sizeme';
import Partner from './Partner';
import type { Partner as PartnerType, Channel } from '../types';
import PageLoader from './PageLoader';

type Props = {
  types: Array<Channel>,
  selectedChannel: Array<number>,
  items: Array<PartnerType>,
  size?: {
    width?: number,
  },
  changeActiveFilter: (t: number) => void,
  changeActiveFilterDropdown: (t: Array<number>) => void,
  clearSelection: () => void,
  clearSlug: () => void,
  slug?: string,
  partnerType: string,
};

function compare(a, b) {
  if (a.title.rendered.toLowerCase() < b.title.rendered.toLowerCase())
    return -1;
  if (a.title.rendered.toLowerCase() > b.title.rendered.toLowerCase()) return 1;
  return 0;
}

const PartnersFilter = ({
  types,
  selectedChannel,
  items,
  size,
  slug,
  partnerType,
  ...props
}: Props) => {
  if (items && items.length > 0) {
    items.sort(compare);
  }

  selectedChannel = selectedChannel ? selectedChannel : [];
  types = types ? types : [];

  // channel 23 is media partners
  // channel 24 is product partners
  const mediaPartners = items
    ? items.filter((item) => item.channel.includes(23))
    : [];
  const productPartners = items
    ? items.filter((item) => item.channel.includes(24))
    : [];

  let visibleMediaPartnersCount = 0;
  let visibleProductPartnersCount = 0;

  let dropdownTypes = [];

  for (let i = 0, len = types.length; i < len; i++) {
    dropdownTypes[i] = {
      key: types[i].name,
      text: types[i].name,
      value: types[i].id,
    };
  }
  const tabletWidth = partnerType === 'media' ? 7 : 12;
  const desktopWidth = partnerType === 'media' ? 9 : 12;

  return (
    <Container>
      <Grid>
        {partnerType === 'media' && (
          <Grid.Column width={12} tablet={5} computer={3}>
            <div className="partners-filter show-for-small">
              <h4>Filter</h4>
              {selectedChannel.length > 0 && (
                <button
                  onClick={props.clearSelection}
                  onKeyPress={props.clearSelection}
                  tabIndex={0}
                  className="partners-filter__clear"
                >
                  Clear
                </button>
              )}
              <div className="partners-filter__items">
                {types
                  .filter((t) => !t.acf.topLevel)
                  .map((type, i) => {
                    if (
                      type.count > 0 &&
                      type &&
                      type.acf &&
                      type.acf.channelIcon &&
                      type.acf.channelIcon.url
                    ) {
                      let isActive = false;

                      for (
                        let i = 0, len = selectedChannel.length;
                        i < len;
                        i++
                      ) {
                        if (type.id === selectedChannel[i]) {
                          isActive = true;
                        }
                      }

                      return (
                        <button
                          className={`partners-filter__item ${
                            isActive ? 'partners-filter__item--active' : ''
                          }`}
                          style={{
                            backgroundImage: `url(${
                              type.acf.channelIcon && type.acf.channelIcon.url
                            })`,
                          }}
                          key={i}
                          onClick={(e) => props.changeActiveFilter(type.id)}
                        >
                          <Form.Field
                            control={Checkbox}
                            checked={isActive ? true : false}
                          />
                          <span className="partners-filter__name">
                            {type.name}
                          </span>
                        </button>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="hide-for-small text--center">
              <Dropdown
                button
                labeled
                options={dropdownTypes}
                text="Filter Partners"
                multiple
                onChange={(event, data) =>
                  props.changeActiveFilterDropdown(data.value)
                }
                value={selectedChannel}
              />
            </div>
          </Grid.Column>
        )}
        <Grid.Column width={12} tablet={tabletWidth} computer={desktopWidth}>
          <div className="partners">
            {mediaPartners.length > 0 && productPartners.length > 0 ? (
              <Grid centered stretched>
                {partnerType === 'media' && (
                  <Grid.Column width={12}>
                    <h3 className="partners-filter__media-title">
                      Media Partners
                    </h3>
                  </Grid.Column>
                )}
                {partnerType === 'media' &&
                  mediaPartners.map((item, i) => {
                    const isInCurrentChannel =
                      item.channel.filter((elem) => {
                        return selectedChannel.indexOf(elem) > -1;
                      }).length === selectedChannel.length;

                    if (isInCurrentChannel) {
                      visibleMediaPartnersCount++;
                    }

                    return isInCurrentChannel ||
                      selectedChannel.length === 0 ? (
                      <Grid.Column key={i} width={6} computer={4}>
                        <Partner
                          partner={item}
                          channels={types}
                          slug={slug}
                          clearSlug={props.clearSlug}
                          partnerType={partnerType}
                        />
                      </Grid.Column>
                    ) : null;
                  })}
                {partnerType === 'media' && visibleMediaPartnersCount === 0 && (
                  <div className="partners-filter__no-match">
                    No media partners match these filters.{' '}
                    <button
                      onClick={props.clearSelection}
                      onKeyPress={props.clearSelection}
                      tabIndex={0}
                      className="btn--link"
                    >
                      Clear filter
                    </button>
                  </div>
                )}
                {partnerType === 'partners' && (
                  <Grid.Column width={12}>
                    <h3>Solution Partners</h3>
                  </Grid.Column>
                )}

                {partnerType === 'partners'
                  ? productPartners.map((item, i) => {
                      const isInCurrentChannel =
                        item.channel.filter((elem) => {
                          return selectedChannel.indexOf(elem) > -1;
                        }).length === selectedChannel.length;

                      if (isInCurrentChannel) {
                        visibleProductPartnersCount++;
                      }

                      return isInCurrentChannel ||
                        selectedChannel.length === 0 ? (
                        <Grid.Column key={i} width={6} computer={4}>
                          <Partner
                            partner={item}
                            channels={types}
                            slug={slug}
                            clearSlug={props.clearSlug}
                            partnerType={partnerType}
                          />
                        </Grid.Column>
                      ) : null;
                    })
                  : ''}
                {partnerType === 'partners' &&
                  visibleProductPartnersCount === 0 && (
                    <div className="partners-filter__no-match">
                      No solution partners match these filters.{' '}
                      <button
                        onClick={props.clearSelection}
                        onKeyPress={props.clearSelection}
                        tabIndex={0}
                        className="btn--link"
                      >
                        Clear filter
                      </button>
                    </div>
                  )}
              </Grid>
            ) : (
              <PageLoader />
            )}
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default (sizeMe()(PartnersFilter): any);
