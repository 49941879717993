// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import * as T from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_PERSONAS = 'REQUEST_PERSONAS';
function requestPersonas() {
  return {
    type: REQUEST_PERSONAS,
  };
}

export const RECEIVE_PERSONAS = 'RECEIVE_PERSONAS';
function receivePersonas(json) {
  return {
    type: RECEIVE_PERSONAS,
    personas: json,
  };
}

export function fetchPersonas(helixCountry: string): any {
  return function (dispatch): T.Dispatch {
    dispatch(requestPersonas());
    // return fetch(`${API_BASE}/helix/persona_excerpts/?livecms=true`)
    // $FlowFixMe
    return fetch(`${API_BASE}/${helixCountry}/persona_excerpts/?livecms=true`)
      .then(
        (response) => (response.json(): Promise<Array<Object>>),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then((json) => {
        let helixCountryJson;
        if (helixCountry === 'helix_nz') {
          // $FlowFixMe
          helixCountryJson = camelizeKeys(json.nz_personas, noCamelize);
        } else if (helixCountry === 'helix_id') {
          // $FlowFixMe
          helixCountryJson = camelizeKeys(json.indo_personas, noCamelize);
        } else {
          // $FlowFixMe
          helixCountryJson = camelizeKeys(json.personas, noCamelize);
        }
        return dispatch(receivePersonas(helixCountryJson));
      });
  };
}
