// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Modal } from 'semantic-ui-react';
import ContactForm from './ContactForm';
import { withRouter } from 'react-router-dom';
import type { SendContact } from '../types';

type Props = {
  url: string,
  history: any,
  reportSuccessMessage: ?string,
  sendContact: SendContact,
};

type State = {
  success: boolean,
  error: boolean,
  submitted: boolean,
};

class DownloadReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      submitted: false,
    };
  }

  onSubmit = async (values: Object) => {
    try {
      this.setState({
        submitted: true,
      });

      const result = await this.props.sendContact(
        values,
        'Live Download Report',
        'Download Report',
        window.location.href
      );

      this.setState({
        success: result,
        error: !result,
      });

      const { url } = this.props;

      window.location.href = url;
    } catch (error) {
      Sentry.captureException(error);
      this.setState({
        success: false,
        error: true,
      });
    }
  };

  render() {
    const { url, reportSuccessMessage } = this.props;

    return (
      <Modal
        trigger={<button className="ui button">Download</button>}
        closeIcon={<button className="modal__close">Close</button>}
      >
        <Modal.Header>Download Report</Modal.Header>
        <Modal.Content>
          <ContactForm
            report
            reportUrl={url}
            success={this.state.success}
            error={this.state.error}
            submitted={this.state.submitted}
            onSubmit={this.onSubmit}
            reportSuccessMessage={reportSuccessMessage}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default (withRouter(DownloadReport): any);
