// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import Head from '../components/Head';
import PageTitle from '../components/PageTitle';
import PageLoader from '../components/PageLoader';
import PageContent from '../components/PageContent';
import { fetchProducts, fetchSuites, fetchPage } from '../actions';
import type { Product, Suite, DefaultPage, Dispatch } from '../types';

const PAGE_ID = 207;

type Props = {
  products?: Array<Product>,
  suites?: Array<Suite>,
  isFetching?: boolean,
  page: ?DefaultPage,
  fetchProducts: () => Promise<void>,
  fetchSuites: () => Promise<void>,
  fetchPage: (i: number) => Promise<void>,
};

class Products extends React.Component<Props> {
  componentDidMount() {
    const { fetchProducts, fetchSuites, fetchPage } = this.props;
    fetchPage(PAGE_ID);
    fetchProducts();
    fetchSuites();
  }

  render() {
    const { products, suites, page, isFetching } = this.props;
    let pageRows;
    let pageCopy;

    if (!page || !page.acf || isFetching) {
      return <PageLoader />;
    }

    if (page && page.content) {
      pageCopy = page.content.rendered;
    }
    if (page && page.acf) {
      pageRows = page.acf.pageRows;
    }

    return (
      <div>
        <Head metaTags={page ? page.yoast : null} />
        <PageTitle title="Products" />

        {(!products || !suites || !page) && <PageLoader />}

        {pageCopy && (
          <div className="page-row page-row--min-bottom-padding">
            <Container>
              <Grid centered stackable>
                <Grid.Column width={8}>
                  <div>{ReactHtmlParser(pageCopy)}</div>
                </Grid.Column>
              </Grid>
            </Container>
          </div>
        )}

        <div className="product-listing-page-content">
          {pageRows && <PageContent pageContent={pageRows} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products.items,
    suites: state.suites.items,
    page: state.pages[PAGE_ID],
    isFetching: state.pages.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchProducts,
      fetchSuites,
      fetchPage,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Products): any);
