// @flow strict
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DownloadReport from '../components/DownloadReport';
import { sendContact } from '../actions';
import type { Dispatch } from '../types';

const mapStateToProps = state => {
  return {
    reportSuccessMessage: state.settings.downloadReportFormSubmitMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      sendContact,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReport): any);
