// TODO: try encodeURLComponent
function makeURLFriendly(title) {
  return title.toLowerCase().replace(/\s+/g, '-').replaceAll(',', '');
}

function makePersonaURLFriendly(title) {
  return title.replace(/\s+/g, '').replaceAll(',', '').toLowerCase();
}

export const makeCommunityURL = (slug, title) => {
  return slug + '-' + makeURLFriendly(title);
};

export const makePersonaURL = (title) => {
  return makePersonaURLFriendly(title.split("-")[0]) + makeURLFriendly(title.split("-")[1])
};
