// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import Head from '../components/Head';
import PageTitle from '../components/PageTitle';
import PageLoader from '../components/PageLoader';
import PageContent from '../components/PageContent';
import { fetchSuites, fetchPage, fetchProducts } from '../actions';
import type { Suite, DefaultPage, Product, State, Dispatch } from '../types';

const PAGE_ID = 424;

type Props = {
  suites?: Array<Suite>,
  page: ?DefaultPage,
  products?: Array<Product>,
  fetchSuites: () => Promise<void>,
  fetchProducts: () => Promise<void>,
  fetchPage: (i: number) => Promise<void>,
};

class Suites extends React.Component<Props> {
  componentDidMount() {
    const { fetchSuites, fetchPage, fetchProducts } = this.props;
    fetchPage(PAGE_ID);
    fetchSuites();
    fetchProducts();
  }

  render() {
    const { suites, page } = this.props;
    let pageRows;
    let pageCopy;

    if (page && page.content) {
      pageCopy = page.content.rendered;
    }

    if (page && page.acf) {
      pageRows = page.acf.pageRows;
    }

    return (
      <div>
        {page && <Head metaTags={page.yoast} />}
        <PageTitle title="Suites" />

        {(!suites || !page) && <PageLoader />}

        {pageCopy && (
          <div className="page-row page-row--min-bottom-padding">
            <Container>
              <Grid centered stackable>
                <Grid.Column width={8}>
                  <div>{ReactHtmlParser(pageCopy)}</div>
                </Grid.Column>
              </Grid>
            </Container>
          </div>
        )}

        <div className="product-listing-page-content">
          {pageRows && <PageContent pageContent={pageRows} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    suites: state.suites.items,
    page: state.pages[PAGE_ID],
    products: state.products.items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchSuites,
      fetchPage,
      fetchProducts,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Suites): any);
