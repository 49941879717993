// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Head from '../components/Head';
import PageContent from '../components/PageContent';
import PageTitle from '../components/PageTitle';
import { fetchPage, fetchPartners, fetchChannels } from '../actions';
import PageLoader from '../components/PageLoader';
import PartnersFilter from '../components/PartnersFilter';
import type { PartnersPage, Partner, Channel, Dispatch } from '../types';

const PAGE_ID = 313;

type Props = {
  page: PartnersPage,
  partners: Array<Partner>,
  channels: Array<Channel>,
  isFetching: boolean,
  fetchPage: (i: number) => Promise<void>,
  fetchPartners: () => Promise<void>,
  fetchChannels: () => Promise<void>,
  history: any,
  match?: {
    params: {
      slug: string,
    },
  },
};

type State = {
  selectedChannel: Array<number>,
  visiblePartners: Array<Partner>,
};

class Partners extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visiblePartners: props.partners,
      selectedChannel: [],
    };
  }

  componentDidMount() {
    const { fetchPage, fetchPartners, fetchChannels } = this.props;
    fetchPage(PAGE_ID);
    fetchPartners();
    fetchChannels();
  }

  changeActiveChannelDropdown = (channels: Array<number>) => {
    this.setState({
      selectedChannel: channels,
    });
  };

  changeActiveChannel = (channel: number) => {
    let addToArray = true;

    for (let i = 0, len = this.state.selectedChannel.length; i < len; i++) {
      if (this.state.selectedChannel[i] === channel) {
        addToArray = false;
      }
    }

    // Channel isn't selected yet - add it to the filter.
    if (addToArray) {
      this.setState(prevState => ({
        selectedChannel: [...prevState.selectedChannel, channel],
      }));
    } else {
      // Channel is already selected - remove it from the filter
      const workingArray = this.state.selectedChannel;
      const itemToRemove = workingArray.indexOf(channel);
      workingArray.splice(itemToRemove, 1);

      this.setState({
        selectedChannel: workingArray,
      });
    }
  };

  clearSelection = () => {
    this.setState({ selectedChannel: [] });
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.partners !== this.props.partners) {
      this.setState({
        visiblePartners: nextProps.partners,
      });
    }
  }

  clearSlug = () => {
    this.props.history.push('/partners/');
  };

  render() {
    const { page, isFetching, channels, match } = this.props;
    const { selectedChannel } = this.state;
    const partnerType = 'partners';
    if (!page || !page.acf || isFetching) {
      return <PageLoader />;
    }
    return (
      <div>
        <Head metaTags={page.yoast} />
        <PageTitle title="Partners" />

        <PageContent
          pageContent={page.acf.pageContent}
          extraClass=" partners__intro"
        />
        <div className="page-row page-row--background-athens-grey">
          <PartnersFilter
            items={this.state.visiblePartners}
            types={channels}
            changeActiveFilter={this.changeActiveChannel}
            changeActiveFilterDropdown={this.changeActiveChannelDropdown}
            clearSelection={this.clearSelection}
            selectedChannel={selectedChannel}
            slug={match && match.params ? match.params.slug : undefined}
            clearSlug={this.clearSlug}
            partnerType={partnerType}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    page: state.pages[PAGE_ID],
    isFetching: state.pages.isFetching && state.partners.isFetching,
    partners: state.partners.items,
    channels: state.partners.channels,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchPage,
      fetchPartners,
      fetchChannels,
    },
    dispatch
  );
};

export default (withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Partners)
): any);
