// @flow
import * as React from 'react';
import type { Resource } from '../../types';
import { Container, Grid } from 'semantic-ui-react';
import Share from '../Share';
import MoreResources from '../MoreResources';
import DownloadReport from '../../containers/DownloadReport';
import ReactHtmlParser from 'html-react-parser';
import { format } from 'date-fns';
import * as basicLightbox from 'basiclightbox';

type Props = {
  resource: Resource,
};

class Report extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    // For lightbox functionality
    const galleryItems = document.querySelectorAll('.gallery-item');

    [].forEach.call(galleryItems, elem => {
      let anchor = elem.querySelector('a');

      anchor.addEventListener('click', function(e) {
        e.preventDefault();
      });

      elem.onclick = basicLightbox.create(elem.outerHTML).show;
    });
  }
  render(): React.Element<"div"> {
    const { resource } = this.props;
    return (
      <div>
        <div className="resource-single">
          <Container>
            <Grid stackable className="resource-single__report">
              <Grid.Column tablet={12} computer={9}>
                <div className="resource-single__meta">
                  <h1 className="resource-single__title">
                    {resource.title && ReactHtmlParser(resource.title.rendered)}
                  </h1>
                  <div className="resource-single__meta-flex">
                    <div className="resource-single__meta-date">
                      {format(new Date(resource.date), 'd MMM yyyy')}
                    </div>
                    <div className="resource-single__meta-share">
                      <Share inline title={resource.yoast.title} />
                    </div>
                  </div>
                </div>
                <div className="resource-single__content">
                  {resource.content &&
                    ReactHtmlParser(resource.content.rendered)}
                </div>
              </Grid.Column>
              {resource.acf && resource.acf.resourceDownload && (
                <Grid.Column tablet={12} computer={3}>
                  <div className="callout resource-single__cta">
                    <p>
                      To download this report for free, please provide us with
                      some information about yourself
                    </p>
                    <DownloadReport url={resource.acf.resourceDownload.url} />
                  </div>
                </Grid.Column>
              )}
            </Grid>
            <div className="resource-single__footer">
              <Share large title={resource.yoast.title} />
            </div>
          </Container>
        </div>
        {resource.acf && resource.acf.moreResources && (
          <MoreResources
            title={resource.acf.moreResourcesTitle}
            resources={resource.acf.moreResources}
          />
        )}
      </div>
    );
  }
}

export default Report;
