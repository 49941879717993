// @flow
import * as React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Button, Container, Grid } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import largeComputerSvg from '../../assets/images/icons/large-computer.svg';
import Link from '../Link';
import { type ProductWalkThrough as ContentType } from '../../types';

type Props = {
  row: ContentType,
};

class ProductWalkThrough extends React.Component<Props> {
  render(): React.Element<"div"> {
    const { productWalkThroughRepeater, link, text } = this.props.row;

    return (
      <div className="page-row ">
        <div className="demo-carousel">
          <img
            src={largeComputerSvg}
            className="demo-carousel__silhouette"
            alt=""
          />
          <OwlCarousel
            items={1}
            margin={20}
            nav
            navText={['', '']}
            center
            animateIn="demo-carousel--fade-in"
            animateOut="demo-carousel--fade-out"
          >
            {productWalkThroughRepeater.map((video, i) => (
              <div
                className="demo-carousel__item"
                key={`walkthrough-video-${i}`}
              >
                {ReactHtmlParser(video.videoSource)}
              </div>
            ))}
          </OwlCarousel>
        </div>
        <Container>
          <div className="demo-carousel__text">
            {text && (
              <Grid stackable centered>
                <Grid.Column tablet={8} computer={6}>
                  {ReactHtmlParser(text)}
                </Grid.Column>
              </Grid>
            )}
          </div>
          {link && link.title && link.url && (
            <div className="page-row__buttons">
              <Button as={Link} url={link.url}>
                {ReactHtmlParser(link.title)}
              </Button>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default ProductWalkThrough;
