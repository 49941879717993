// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import type { ThunkAction, Action, ResourceType, Resource, Dispatch } from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_RESOURCES = 'REQUEST_RESOURCES';
function requestResources():Action {
  return {
    type: REQUEST_RESOURCES,
  };
}

export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES';
function receiveResources(json: Array<Resource>, page: number): Action {
  return {
    type: RECEIVE_RESOURCES,
    resources: json,
    page,
  };
}

export const NO_MORE_RESOURCES = 'NO_MORE_RESOURCES';
function noMoreResources(): Action {
  return {
    type: NO_MORE_RESOURCES,
  };
}

export const fetchResources = (
  page: number = 1,
  resourceTypeId: ?number,
  perPage: number = 10
): ThunkAction => async (dispatch: Dispatch) => {
  let url = `${API_BASE}/wp-json/wp/v2/posts?per_page=${perPage}&page=${page}`;
  if (resourceTypeId) {
    url += `&resource_format=${resourceTypeId}`;
  }
  dispatch(requestResources());
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const json = await response.json();
    const camelizedJson = camelizeKeys(json, noCamelize);
    return dispatch(receiveResources(camelizedJson, page));
  } catch (e) {
    Sentry.captureException(e);
    return dispatch(noMoreResources());
  }
};
// }

export const REQUEST_RESOURCE_TYPES = 'REQUEST_RESOURCE_TYPES';
function requestResourceTypes(): Action {
  return {
    type: REQUEST_RESOURCE_TYPES,
  };
}

export const RECEIVE_RESOURCE_TYPES = 'RECEIVE_RESOURCE_TYPES';
function receiveResourceTypes(json: Array<ResourceType>): Action {
  return {
    type: RECEIVE_RESOURCE_TYPES,
    resourceTypes: json,
  };
}

export function fetchResourceTypes(): ThunkAction {
  return function (dispatch: Dispatch) {
    dispatch(requestResourceTypes());
    return fetch(`${API_BASE}/wp-json/wp/v2/resource_format`)
      .then(
        (response) => response.json(),
        (error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then((json) => {
        const camelizedJson = camelizeKeys(json, noCamelize);
        return dispatch(receiveResourceTypes(camelizedJson));
      });
  };
}

export const REQUEST_RESOURCE = 'REQUEST_RESOURCE';
function requestResource(): Action {
  return {
    type: REQUEST_RESOURCE,
  };
}

export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE';
function receiveResource(slug, json): Action {
  return {
    type: RECEIVE_RESOURCE,
    resource: json,
    slug,
  };
}

// TODO: Handle Resource not found.
export function fetchResource(slug: string): ThunkAction {
  return function (dispatch: Dispatch) {
    dispatch(requestResource());
    return fetch(`${API_BASE}/wp-json/wp/v2/posts?slug=${slug}`)
      .then(
        (response) => response.json(),
        (error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then((json) => {
        const camelizedJson = camelizeKeys(json, noCamelize);
        return dispatch(receiveResource(slug, camelizedJson[0]));
      });
  };
}
