// @flow
import { REQUEST_SETTINGS, RECEIVE_SETTINGS } from '../actions';
import type { Settings } from '../types';

type State = {
  +isFetching: boolean,
};

type Action = {
  +type: string,
  settings: Settings,
};

const initialState = {
  isFetching: false,
};

function settings(state: State = initialState, action: Action): State {
  switch (action.type) {
    case REQUEST_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_SETTINGS:
      return {
        ...state,
        isFetching: false,
        ...action.settings,
      };
    default:
      return state;
  }
}

export default settings;
