// @flow
import * as React from 'react';
import SingleColumnRow from './PageContent/SingleColumnRow';
import FeaturedProducts from './PageContent/FeaturedProducts';
import Testimonials from './PageContent/Testimonials';
import ClientLogos from './PageContent/ClientLogos';
import ContactFormRow from '../containers/PageContent/ContactFormRow';
import ProductRow from './PageContent/ProductRow';
import SuiteRow from './PageContent/SuiteRow';
import IntroCopy from './PageContent/IntroCopy';
import IndustryCallouts from './PageContent/IndustryCallouts';
import ExtendedContactInformation from './PageContent/ExtendedContactInformation';
import SocialMediaRow from '../containers/PageContent/SocialMediaRow';
import VideoRow from './PageContent/VideoRow';
import LeadRow from './PageContent/LeadRow';
import ProductWalkThrough from './PageContent/ProductWalkThrough';
import FeatureGrid from './PageContent/FeatureGrid';
import IconSteps from './PageContent/IconSteps';
import SuiteProductRow from './PageContent/SuiteProductRow';
import HelixPersonaList from './PageContent/HelixPersonaList';
import HelixNZPersonaList from './PageContent/HelixNZPersonaList';
import HelixIndoPersonaList from './PageContent/HelixIndoPersonaList';
import PremiumSegmentList from './PageContent/PremiumSegmentList';
import InlineCallbackFormRow from '../containers/PageContent/InlineCallbackFormRow';
import IntroCopyWithCallbackForm from '../containers/PageContent/IntroCopyWithCallbackForm';
import FeatureScreenshots from './PageContent/FeatureScreenshots';
import GetStartedWithProductCTA from '../containers/PageContent/GetStartedWithProductCTA';
import ContactOptions from './PageContent/ContactOptions';

// helix imports
import WelcomeBanner from './PageContent/WelcomeBanner';
import VideoAndContent from './PageContent/VideoAndContent';
import CommunitySummaries from './PageContent/CommunitySummeries';
import InfoTab from './PageContent/InfoTab';
import ImageAndContent from './PageContent/ImageAndContent';
import FeaturedResources from './PageContent/FeaturedResources';
import TwoColumn from './PageContent/TwoColumn';

import type { PageContentArray } from '../types';

type Props = {
  pageContent: ?PageContentArray,
  extraClass?: string,
  clearSlug?: Function,
  clearSlugNZ?: Function,
  clearSlugIndo?: Function,
  persona_slug?: string,
};

const PageContent = ({
  pageContent,
  extraClass,
  clearSlug,
  clearSlugNZ,
  clearSlugIndo,
  persona_slug,
}: Props): string | Array<string | React.Node> => {
  if (pageContent) {
    // $FlowFixMe
    return pageContent.map((content, i) => {
      switch (content.acfFcLayout) {
        case 'welcome_banner':
          return <WelcomeBanner key={i} row={content} />;
        case 'community_summaries':
          return <CommunitySummaries key={i} row={content} />;
        case 'single_column_row':
          return <SingleColumnRow key={i} row={content} />;
        case 'featured_products':
          return <FeaturedProducts key={i} row={content} />;
        case 'featured_resources':
          return <FeaturedResources key={i} row={content} />;
        case 'testimonials':
          return <Testimonials key={i} row={content} />;
        case 'client_logos':
          return <ClientLogos key={i} row={content} />;
        case 'intro_copy':
          return <IntroCopy key={i} row={content} extraClass={extraClass} />;
        case 'industry_callouts':
          return <IndustryCallouts key={i} row={content} />;
        case 'feature_grid':
          return <FeatureGrid key={i} row={content} />;
        case 'icon_steps':
          return <IconSteps key={i} row={content} />;
        case 'video_row':
          //  $FlowFixMe
          return <VideoRow key={i} row={content} />;
        case 'product_walk_through':
          return <ProductWalkThrough key={i} row={content} />;
        case 'lead_row':
          return <LeadRow key={i} row={content} />;
        case 'contact_form_with_text':
          return <ContactFormRow key={i} row={content} />;
        case 'extended_contact_information':
          return <ExtendedContactInformation key={i} row={content} />;
        case 'social_media_row_with_title':
          return <SocialMediaRow key={i} row={content} />;
        case 'suite_product_row':
          return <SuiteProductRow key={i} row={content} />;
        case 'inline_callback_form':
          return <InlineCallbackFormRow key={i} row={content} />;
        case 'intro_copy_with_callback_form':
          return <IntroCopyWithCallbackForm key={i} row={content} />;
        case 'feature_screenshots':
          return <FeatureScreenshots key={i} row={content} />;
        case 'get_started_with_product_cta':
          return <GetStartedWithProductCTA key={i} row={content} />;
        case 'contact_options':
          return <ContactOptions key={i} row={content} />;
        case 'premium_segments_list':
          return <PremiumSegmentList key={i} row={content} />;
        case 'two_column':
          return <TwoColumn key={i} row={content} />;
        case 'info_tab':
          return <InfoTab key={i} row={content} />;
        case 'image_and_content':
          return <ImageAndContent key={i} row={content} />;
        case 'video_and_content':
          return <VideoAndContent key={i} row={content} />;
        case 'helix_persona_list':
          return (
            <HelixPersonaList
              key={i}
              row={content}
              persona_slug={persona_slug}
              clearSlug={clearSlug}
            />
          );
        case 'helix_persona_list_nz':
          return (
            <HelixNZPersonaList
              key={i}
              row={content}
              persona_slug={persona_slug}
              clearSlug={clearSlugNZ}
            />
          );
        case 'helix_persona_list_id':
          return (
            <HelixIndoPersonaList
              key={i}
              row={content}
              persona_slug={persona_slug}
              clearSlug={clearSlugIndo}
            />
          );
        case 'suite_row':
          return <SuiteRow key={i} row={content} />;
        case 'product_row':
          return <ProductRow key={i} row={content} />;
        default:
          console.warn('Missing Layout: ' + content.acfFcLayout); // eslint-disable-line
          return '';
      }
    });
  } else {
    return '';
  }
};

export default PageContent;
