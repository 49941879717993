// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Grid, Container } from 'semantic-ui-react';
import InlineCallbackForm from '../InlineCallbackForm';
import type {
  InlineCallbackForm as ContentType,
  SendContact,
} from '../../types';

type Props = {
  row: ContentType,
  sendContact: SendContact,
};

type State = {
  success: boolean,
  error: boolean,
  submitted: boolean,
};

class InlineCallbackFormRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      submitted: false,
    };
  }

  onSubmit: ((values: any) => Promise<void>) = async (values: Object) => {
    try {
      this.setState({
        submitted: true,
      });
      const result = await this.props.sendContact(
        values,
        'Live Request Callback',
        'Request Callback',
        window.location.href
      );
      this.setState({
        success: result,
        error: !result,
      });
    } catch (error) {
      Sentry.captureException(error);
      this.setState({
        success: false,
        error: true,
      });
    }
  };

  render(): React.Element<"div"> {
    return (
      <div className="inline-callback-form-row">
        <Container>
          <Grid stretched>
            <Grid.Column width={12} computer={4}>
              <h3 className="inline-callback-form__title">
                Ready to get started?
              </h3>
            </Grid.Column>
            <Grid.Column width={12} computer={8}>
              <InlineCallbackForm
                onSubmit={this.onSubmit}
                success={this.state.success}
                error={this.state.error}
                submitted={this.state.submitted}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default InlineCallbackFormRow;
