// @flow
import * as React from 'react';
import PersonaBanner from './PersonaBanner';
import CommunitySummaryTile from '../CommunitySummaryTile';
import CommunityCarousel from '../CommunityCarousel';
import { Container } from 'semantic-ui-react';
import { makePersonaURL } from '../../helpers/makeURL';

type Props = {
  welcomePage: Object,
  community: Object,
  personas: Object,
  location: String,
};

const PersonaLanding = ({
  welcomePage,
  community,
  personas,
  location,
}: Props): React.Element<typeof React.Fragment> => {
  let url = window?.location?.pathname;
  let countryFromUrl = url.split('/')[2];

  const [country, setCountry] = React.useState('helix-personas');

  React.useEffect(() => {
    if (countryFromUrl.includes('nz')) {
      setCountry('helix-personas-nz');
    } else if (countryFromUrl.includes('id')) {
      setCountry('helix-personas-id');
    } else {
      setCountry('helix-personas');
    }
  }, [countryFromUrl]);

  return (
    <>
      <Container>
        <PersonaBanner community={community} welcomePage={welcomePage} />
        <div className="persona-tiles__grid">
          {community?.personas
            .sort(function (a, b) {
              return a.postTitle === undefined || b.postTitle === undefined
                ? 0
                : a.postTitle.toLowerCase() < b.postTitle.toLowerCase()
                ? -1
                : a.postTitle.toLowerCase() > b.postTitle.toLowerCase()
                ? 1
                : 0;
            })
            .map((persona, key) => (
              <CommunitySummaryTile
                key={key}
                id={persona.slug}
                name={persona.acf.title}
                image={
                  persona?.acf?.featuredImage?.url
                    ? persona?.acf?.featuredImage?.url
                    : persona?.knowMeImageGallery[0]?.url
                    ? persona?.knowMeImageGallery[0]?.url
                    : ''
                }
                isSummaryFixed
                summary={persona.acf.summaryExcerpt}
                url={
                  makePersonaURL(community?.postTitle) +
                  '/' +
                  makePersonaURL(persona?.postTitle)
                }
                colour={community.acf.color}
                stat1img={persona.acf?.excerptIcon1?.image?.url}
                stat1label={persona.acf.excerptLabel1}
                stat1={persona.acf.excerptValue1}
                excerptNumberOnLeft1={persona.acf.excerptNumberOnLeft1}
                stat2img={persona.acf?.excerptIcon2?.image?.url}
                stat2label={persona.acf.excerptLabel2}
                stat2={persona.acf.excerptValue2}
                excerptNumberOnLeft2={persona.acf.excerptNumberOnLeft2}
                personaCarousel={true}
                communityCarousel={false}
              />
            ))}
        </div>
        <CommunityCarousel
          welcomePage={welcomePage}
          personas={personas}
          countryUrl={`/products/${country}/personas`}
          // countryUrl={'/products/helix-personas-id/personas'}
          fullWidth={false}
          communityCarousel={false}
        />
      </Container>
    </>
  );
};

export default PersonaLanding;
