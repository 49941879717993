// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Head from '../components/Head';
import { fetchProducts } from '../actions';
import { findBySlug } from '../helpers';
import PageLoader from '../components/PageLoader';
import PageContent from '../components/PageContent';
import { withRouter } from 'react-router-dom';
import type { Products, Product as ProductType, Dispatch } from '../types';

type Props = {
  products: Products,
  match: {
    params: {
      slug: string,
      persona_slug: string,
    },
  },
  history: any,
  fetchProducts: () => Promise<void>,
};

class Product extends React.Component<Props> {
  componentDidMount() {
    const { fetchProducts } = this.props;
    fetchProducts();
  }

  clearSlug = () => {
    this.props.history.push('/products/helix-personas');
  };

  clearSlugNZ = () => {
    this.props.history.push('/products/helix-personas-nz');
  };
  
  clearSlugIndo = () => {
    this.props.history.push('/products/helix-personas-id');
  };

  render() {
    const { products, match } = this.props;
    const slug = match.params.slug;
    const persona_slug = match.params.persona_slug;

    let currentProduct: ?ProductType;

    if (products && products.items) {
      // $FlowFixMe
      currentProduct = (findBySlug(products.items, slug): ProductType);
    }

    if (!currentProduct) {
      return <PageLoader />;
    }

    let currentProductMeta;

    // Yoast doesn't work for custom taxonomies. Using custom fields.
    if (currentProduct) {
      const metaTitle: string =
        currentProduct.acf && currentProduct.acf.customSeoTitle
          ? currentProduct.acf.customSeoTitle
          : currentProduct.title.rendered + ' | Roy Morgan Live';

      const metaDesc: ?string =
        currentProduct.acf && currentProduct.acf.customSeoDescription
          ? currentProduct.acf.customSeoDescription
          : undefined;

      currentProductMeta = {
        title: metaTitle,
        metaDesc,
      };
    }

    return (
      <div>
        {currentProduct && <Head metaTags={currentProductMeta} />}
        <PageContent
          pageContent={
            currentProduct.acf ? currentProduct.acf.pageContent : undefined
          }
          clearSlug={this.clearSlug}
          clearSlugNZ={this.clearSlugNZ}
          clearSlugIndo={this.clearSlugIndo}
          persona_slug={persona_slug}
        />
        {/* <OtherProducts currentProduct={currentProduct} /> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchProducts,
    },
    dispatch
  );
};

export default (withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Product)
): any);
