// @flow strict
import {
  REQUEST_RESOURCES,
  RECEIVE_RESOURCES,
  REQUEST_RESOURCE_TYPES,
  RECEIVE_RESOURCE_TYPES,
  REQUEST_RESOURCE,
  RECEIVE_RESOURCE,
  NO_MORE_RESOURCES,
} from '../actions';
import type { Resource, ResourceType } from '../types';

type State = {
  +isFetching: boolean,
  +items: Array<Resource>,
};

type Action = {
  +type: string,
  +resources: Array<Resource>,
  +resourceTypes?: Array<ResourceType>,
  +resource: Resource,
  +page?: number,
};

const initialState = {
  isFetching: false,
  hasMore: true,
  items: [],
};

function resources(state: State = initialState, action: Action): State {
  switch (action.type) {
    case REQUEST_RESOURCES:
    case REQUEST_RESOURCE_TYPES:
    case REQUEST_RESOURCE:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_RESOURCES:
      // eslint-disable-next-line no-case-declarations
      const items =
        action.page && action.resources && action.page > 1
          ? [...state.items, ...action.resources]
          : action.resources;
      return {
        ...state,
        isFetching: false,
        items,
      };
    case RECEIVE_RESOURCE_TYPES:
      return {
        ...state,
        isFetching: false,
        types: action.resourceTypes,
      };
    case RECEIVE_RESOURCE:
      return {
        ...state,
        isFetching: false,
        items: [...state.items, action.resource],
      };
    case NO_MORE_RESOURCES:
      return {
        ...state,
        isFetching: false,
        hasMore: false,
      };
    default:
      return state;
  }
}

export default resources;
