// @flow
import * as React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type Props = {
  title?: string,
  url?: string,
  inline?: ?boolean,
  large?: ?boolean,
};

const Share = ({
  title = document.title,
  url = window.location.href,
  inline,
  large,
}: Props): React.Node => {
  let shareClass;
  let largeClass;

  const windowPopup = (url, width, height) => {
    // Calculate the position of the popup so
    // it’s centered on the screen.
    // eslint-disable-next-line no-restricted-globals
    var left = screen.width / 2 - width / 2,
      // eslint-disable-next-line no-restricted-globals
      top = screen.height / 2 - height / 2;

    window.open(
      url,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' +
        width +
        ',height=' +
        height +
        ',top=' +
        top +
        ',left=' +
        left
    );
  };

  const sharePopup = e => {
    e.preventDefault();
    windowPopup(e.target.href, 500, 300);
  };

  inline
    ? (shareClass = 'share-button-container--inline')
    : (shareClass = 'text--center margin--vert-60');

  large ? (largeClass = 'share-button--large') : (largeClass = '');

  return (
    <Modal
      trigger={
        <div className={shareClass}>
          <button className={`share-button ${largeClass}`}>Share</button>
        </div>
      }
      closeIcon={<button className="modal__close">Close</button>}
    >
      <Modal.Header>Share</Modal.Header>
      <Modal.Content>
        <div className="share-message">
          <div className="share-message__first-col">
            <div className="share-message__user-icon" />
          </div>
          <div className="share-message__text">
            <div className="share-message__title">&ldquo;{title}&rdquo;</div>
            <br />
            <a href={url}>{url}</a>
            <CopyToClipboard text={url}>
              <Button inverted className="share-message__copy">
                Copy
              </Button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="share__socials">
          <a
            onClick={sharePopup}
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
              url
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" size="large" />
            Facebook
          </a>
          <a
            onClick={sharePopup}
            href={`https://twitter.com/intent/tweet/?text=Check%20out%20this%20website!&url=${encodeURI(
              url
            )}&via=RoyMorganOnline`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" size="large" />
            Twitter
          </a>
          <a
            onClick={sharePopup}
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
              url
            )}&title=${title}&source=${encodeURI(url)}&summary=${title}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" size="large" />
            LinkedIn
          </a>
          <a
            href={`mailto:?subject=${title}&body=${encodeURI(url)}`}
            rel="noopener noreferrer"
          >
            <Icon name="mail" size="large" />
            Email
          </a>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default Share;
