// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import * as T from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_SEGMENTS = 'REQUEST_SEGMENTS';
function requestSegments() {
  return {
    type: REQUEST_SEGMENTS,
  };
}

export const RECEIVE_SEGMENTS = 'RECEIVE_SEGMENTS';
function receiveSegments(json) {
  return {
    type: RECEIVE_SEGMENTS,
    segments: json,
  };
}

export function fetchSegments(): T.ThunkAction {
  return function(dispatch): T.Dispatch {
    dispatch(requestSegments());
    // $FlowFixMe
    return fetch(`${API_BASE}/wp-json/premium/v1/segment/all`)
      .then(
        response => (response.json(): Promise<Array<Object>>),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        // $FlowFixMe
        const camelizedJson = camelizeKeys(json.body, noCamelize);
        return dispatch(receiveSegments(camelizedJson));
      });
  };
}
