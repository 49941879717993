// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import type {
  Page,
  Dispatch,
  RequestPageAction,
  RecievePageAction,
  ThunkAction,
} from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_PAGE = 'REQUEST_PAGE';
function requestPage(): RequestPageAction {
  return {
    type: REQUEST_PAGE,
  };
}

export const RECEIVE_PAGE = 'RECEIVE_PAGE';
function receivePage(pageId, json: Page): RecievePageAction {
  return {
    type: RECEIVE_PAGE,
    pageId,
    page: json,
  };
}

export function fetchPage(pageId: number): ThunkAction {
  return function(dispatch: Dispatch) {
    dispatch(requestPage());
    return fetch(`${API_BASE}/wp-json/wp/v2/pages/${pageId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(
        response => (response.json(): Promise<Object>),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        const camelizedJson = (camelizeKeys(json, noCamelize): Page);
        return dispatch(receivePage(pageId, camelizedJson));
      });
  };
}
