// @flow
import * as React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from '../components/Link';
import type { AcfFields } from '../types/personas';
import { loadMarketingPage } from '../actions/readMarketingPage';
import { fetchPersonas } from '../actions/personas';
import { getPersonasSortedAlphabetically } from '../reducers/personas';
import PageLoader from '../components/PageLoader';
import { fetchProducts } from '../actions';
import { findBySlug, isNZHelix, isIndoHelix } from '../helpers';
import type { Product as ProductType, Persona as PersonaType } from '../types';
import CommunityCarousel from '../components/CommunityCarousel';
import PersonaCarousel from '../components/PersonaCarousel';

type Props = {
  acf: AcfFields,
  images: [
    {
      url: string,
      alt: string,
      title: string,
    }
  ],
  fetchPersonas: (helixCountry: string) => Promise<void>,
  loadMarketingPage: (slug: string) => Promise<void>,
  link: string,
  fetchProducts: () => Promise<void>,
  products: Array<Object>,
  match: {
    params: {
      helixPersonas: string,
      community_slug: string,
      persona_slug: string,
    },
  },
  personas: Array<PersonaType>,
  isFetching: () => Promise<void>,
  marketingPage: Object,
  location: Object,
};

class Persona extends React.Component<Props> {
  componentDidMount() {
    if (isNZHelix()) {
      this.props.fetchPersonas('helix_nz');
      this.props.loadMarketingPage('nz-persona-single');
    } else if (isIndoHelix()) {
      this.props.loadMarketingPage('id-persona-single');
      this.props.fetchPersonas('helix_id');
    } else {
      this.props.loadMarketingPage('persona-single');
      this.props.fetchPersonas('helix');
    }
    this.props.fetchProducts();
    window.scrollTo(0, 0);
  }

  render() {
    const currentSlug = this.props.match.params.community_slug.substring(0, 3);
    const currentPersonaSlug = this.props.match.params.persona_slug.substring(
      0,
      3
    );
    const { products } = this.props;
    const slug = 'helix-personas';

    let currentProduct: ?ProductType;
    if (products) {
      currentProduct = (findBySlug(products, slug): ProductType);
    }
    let community;
    //check this.props.personas exists and has lengh > 0
    if (this.props.personas && this.props.personas.length > 0) {
      community = this.props.personas.find(function (item) {
        return item.slug === currentSlug;
      });
    }
    if (
      this.props.isFetching ||
      !this.props.marketingPage ||
      !currentProduct ||
      !community
    ) {
      return <PageLoader />;
    }

    // $FlowFixMe
    const persona: PersonaType = community?.personas?.find(function (item) {
      return item.slug === currentPersonaSlug;
    });

    return (
      <div className="persona-page__wrapper">
        <Container>
          <div className="persona persona-single">
            <div className="persona__banner">
              {persona?.knowMeImageGallery
                ? persona?.knowMeImageGallery?.slice(0, 4).map((img, i) => (
                    <div
                      className={`banner__item banner__item--1-col banner__item--${i}`}
                      key={i}
                    >
                      <img
                        className="img-container__img"
                        src={img.url}
                        alt={img.alt}
                        title={img.title}
                      />
                    </div>
                  ))
                : null}
              <div
                className="banner__item banner__item--blank blank--1"
                style={{ backgroundColor: persona?.acf?.color }}
              />
              <div
                className="banner__item banner__item--blank blank--2"
                style={{ backgroundColor: persona?.acf?.color }}
              />
              <div className="banner__item banner__item--blank blank--3" />
            </div>
            <div className="persona-banner__content-wrapper">
              <div className="persona-banner__content">
                <h3 style={{ color: persona?.acf?.color }}>
                  {persona?.acf?.excerptValue2} of Nation ·{' '}
                  {persona?.acf?.excerptValue1} People
                </h3>
                <h1>
                  {persona?.postName} | {persona?.acf?.title}
                </h1>
                {/* {ReactHtmlParser(persona?.acf?.summaryExcerpt)} */}

                <div className="persona-banner__button-group">
                  {this?.props?.marketingPage?.acf?.personaButtonOne?.url ? (
                    <Link
                      url={
                        this?.props?.marketingPage?.acf?.personaButtonOne?.url
                      }
                      className="button"
                      target={
                        this?.props?.marketingPage?.acf?.personaButtonOne
                          ?.target
                      }
                    >
                      {this?.props?.marketingPage?.acf?.personaButtonOne?.title}
                    </Link>
                  ) : null}
                  {this?.props?.marketingPage?.acf?.personaButtonTwo?.url ? (
                    <Link
                      url={
                        this?.props?.marketingPage?.acf?.personaButtonTwo?.url
                      }
                      className="button"
                      target={
                        this?.props?.marketingPage?.acf?.personaButtonTwo
                          ?.target
                      }
                    >
                      {this?.props?.marketingPage?.acf?.personaButtonTwo?.title}
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="persona__content-wrapper">
          <Container>
            <div className="persona__carousel-wrapper">
              <div className="persona__carousel-heading">
                <h2>View other {community?.acf?.title} Personas</h2>
              </div>
              <PersonaCarousel
                fullWidth={true}
                products={community.personas}
                welcomePage={this.props.marketingPage}
              />
            </div>
            <div className="persona__carousel-wrapper">
              <div className="persona__carousel-heading">
                <h2>View other communities</h2>
              </div>
              <CommunityCarousel
                fullWidth={true}
                personas={this?.props?.personas}
                welcomePage={this?.props?.marketingPage}
                countryUrl={`${this.props.match.params.helixPersonas}/personas`}
                communityCarousel={true}
              />
            </div>

            {this?.props?.marketingPage?.acf?.bottomTextTitle ||
            this?.props?.marketingPage?.acf?.paragraphRepeater ? (
              <div className="persona__bottom-text--container ">
                <div>
                  <h2 className="persona__bottom-text--title">
                    {this?.props?.marketingPage?.acf?.bottomTextTitle}
                  </h2>
                </div>
                <div className="persona__page-end--paragraph--container">
                  {this?.props?.marketingPage?.acf?.bottomTextTitle &&
                    this?.props?.marketingPage?.acf?.paragraphRepeater.map(
                      (paragraph, i) => {
                        return (
                          <div
                            className="persona__bottom-text--content-container"
                            key={i}
                          >
                            <h3 className="persona__bottom-text--heading">
                              {paragraph?.paragraphHeading}
                            </h3>
                            <p className="persona__bottom-text--text"></p>
                            {paragraph?.paragraphText}
                          </div>
                        );
                      }
                    )}
                </div>
                {this?.props?.marketingPage?.acf?.personaButtonRepeater ? (
                  <div className="persona__bottom-button-wrapper">
                    {this?.props?.marketingPage?.acf?.personaButtonRepeater &&
                      this?.props?.marketingPage?.acf?.personaButtonRepeater.map(
                        (button, i) => {
                          return (
                            <div
                              className="persona__bottom-button--container"
                              key={i}
                            >
                              {button?.button?.url && button?.button?.title ? (
                                <Link
                                  url={button?.button?.url}
                                  target={button?.button?.target}
                                  className="button persona__button"
                                >
                                  {button?.button?.title}
                                </Link>
                              ) : null}
                            </div>
                          );
                        }
                      )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    personas: getPersonasSortedAlphabetically(state),
    marketingPage: isNZHelix()
      ? state.marketingPages.pages['nz-persona-single']
      : isIndoHelix()
      ? state.marketingPages.pages['id-persona-single']
      : state.marketingPages.pages['persona-single'],
    isFetching: state.marketingPages.isFetching,
    products: state.products.items,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPersonas,
      loadMarketingPage,
      fetchProducts,
    },
    dispatch
  );

export default (connect<Props, {}, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Persona): any);
