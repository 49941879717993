// @flow
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import InlineSvg from './InlineSvg';

const CommunitySummaryTileReadMoreLink = ({
  text,
  url,
  accessInfo,
  colour,
}: {
  text?: string,
  url: string,
  accessInfo?: any,
  colour: 'white' | 'black',
}): React.Element<typeof Link | typeof Popup> => {

  const readMore = (
    <Link to={url} className="read-more__link">
      {text || 'Read More'}
      <InlineSvg
        className="read-more__svg"
        name="arrow-right"
        colour={"currentColor"}
      />
    </Link>
  );

  if (!accessInfo) return readMore;

  return accessInfo?.paidSubscriber ? (
    readMore
  ) : (
    <Popup
      trigger={
        <button className="read-more__link">
          {text || 'Read More'}
          <InlineSvg
            className="read-more__svg"
            name="arrow-right"
            colour={"currentColor"}
          />
        </button>
      }
      position="top center"
      on="hover"
    >
      <Popup.Content>
        This is a paid subscriber feature. For more info please Contact Roy
        Morgan at{' '}
        <a href="mailto:info@helixpersonas.com.au">info@helixpersonas.com.au</a>
      </Popup.Content>
    </Popup>
  );
};

export default CommunitySummaryTileReadMoreLink;
