// @flow
import * as React from 'react';

type State = {
  intervalId: ?IntervalID,
  visible: boolean,
};

class BackToTop extends React.Component<*, State> {
  constructor() {
    super();

    this.state = {
      intervalId: null,
      visible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll: (() => void) = () => {
    const scrollTop =
      // $FlowFixMe
      document.body.scrollTop || document.documentElement.scrollTop;

    const windowHeight =
      window.innerHeight ||
      // $FlowFixMe
      document.documentElement.clientHeight ||
      // $FlowFixMe
      document.body.clientHeight;

    if (scrollTop > windowHeight) {
      this.setState({
        visible: true,
      });
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  scrollStep: (() => void) = () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  };

  scrollToTop: (() => void) = () => {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  };

  render(): React.Element<"button"> {
    const { visible } = this.state;

    return (
      <button
        title="Back to top"
        className={`back-to-top ${visible ? 'back-to-top--visible' : ''}`}
        onClick={() => {
          this.scrollToTop();
        }}
      />
    );
  }
}

export default BackToTop;
