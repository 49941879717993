// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import type {
  Action,
  Suite,
  ThunkAction,
  Dispatch,
} from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_SUITES = 'REQUEST_SUITES';
function requestSuites(): Action {
  return {
    type: REQUEST_SUITES,
  };
}

export const RECEIVE_SUITES = 'RECEIVE_SUITES';
function receiveSuites(json: Array<Suite>): Action {
  return {
    type: RECEIVE_SUITES,
    suites: json,
  };
}

export function fetchSuites(): ThunkAction {
  return async function(dispatch: Dispatch) {
    dispatch(requestSuites());
    return fetch(`${API_BASE}/wp-json/wp/v2/suites`)
      .then(
        response => response.json(),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        const camelizedJson = (camelizeKeys(json, noCamelize): Array<Suite>);
        return dispatch(receiveSuites(camelizedJson));
      });
  };
}

export const REQUEST_SUITE = 'REQUEST_SUITE';
function requestSuite(): Action {
  return {
    type: REQUEST_SUITE,
  };
}

export const RECEIVE_SUITE = 'RECEIVE_SUITE';
function receiveSuite(slug: string, json: Suite): Action {
  return {
    type: RECEIVE_SUITE,
    suite: json,
    slug,
  };
}

// TODO: Handle Suite not found.
export function fetchSuite(slug: string): ThunkAction {
  return function(dispatch: Dispatch) {
    dispatch(requestSuite());
    return fetch(`${API_BASE}/wp-json/wp/v2/suites?slug=${slug}`)
      .then(
        response => (response.json(): Promise<Object>),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        const camelizedJson: Array<Suite> = camelizeKeys(json, noCamelize);
        return dispatch(receiveSuite(slug, camelizedJson[0]));
      });
  };
}
