// @flow
import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
} from '../actions';
import type { Product, ProductState } from '../types';

type Action = {
  +type: string,
  +payload?: Array<Product>,
};

const initialState = {
  isFetching: false,
  items: [],
};

function products(state: ProductState = initialState, action: Action): ProductState {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    default:
      return state;
  }
}

export default products;
