// @flow
import {
  REQUEST_PARTNERS,
  RECEIVE_PARTNERS,
  REQUEST_CHANNELS,
  RECEIVE_CHANNELS,
} from '../actions';
import type {
  PartnerState as State,
  PartnersActions as Action,
} from '../types';

const initialState = {
  isFetching: false,
  items: [],
  channels: [],
};

function partners(state: State = initialState, action: Action): State {
  switch (action.type) {
    case REQUEST_PARTNERS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PARTNERS:
      return {
        ...state,
        isFetching: false,
        items: action.partners,
      };
    case REQUEST_CHANNELS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CHANNELS:
      return {
        ...state,
        isFetching: false,
        channels: action.channels,
      };
    default:
      return state;
  }
}

export default partners;
