// @flow
import type {Page} from "../types/page";import { REQUEST_PAGE, RECEIVE_PAGE } from '../actions';
import type {
  PageState as State,
  RequestPageAction,
  RecievePageAction,
} from '../types';

type Action = RequestPageAction | RecievePageAction;

const initialState = {
  isFetching: false,
};

function pages(state: State = initialState, action: Action): State | {[number]: Page, isFetching: boolean} {
  switch (action.type) {
    case REQUEST_PAGE:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PAGE:
      return {
        ...state,
        isFetching: false,
        [action.pageId]: {
          ...action.page,
        },
      };
    default:
      return state;
  }
}

export default pages;
