// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Head from '../components/Head';
import { fetchSuites, fetchProducts } from '../actions';
import PageTitle from '../components/PageTitle';
import PageLoader from '../components/PageLoader';
import PageContent from '../components/PageContent';
import { findBySlug } from '../helpers';
import type { Product, Suite as SuiteType, Dispatch } from '../types';

type Props = {
  products: Array<Product>,
  suites: Array<SuiteType>,
  isFetching: boolean,
  fetchProducts: () => Promise<void>,
  fetchSuites: () => Promise<void>,
  match: {
    params: {
      slug: string,
    },
  },
};

class Suite extends React.Component<Props, *> {
  componentDidMount() {
    const { fetchSuites, fetchProducts } = this.props;
    fetchProducts();
    fetchSuites();
  }

  render() {
    const { suites, match } = this.props;
    const currentSuiteSlug = match.params.slug;

    // Get current suite from redux by slug
    // $FlowFixMe
    const currentSuite: SuiteType = (findBySlug(
      suites,
      currentSuiteSlug
    ): SuiteType);

    // Yoast doesn't work for custom taxonomies. Using custom fields.
    if (currentSuite) {
      const metaTitle: string =
        currentSuite.acf && currentSuite.acf.customSeoTitle
          ? currentSuite.acf.customSeoTitle
          : currentSuite.name + ' | Roy Morgan Live';

      const metaDesc: ?string =
        currentSuite.acf && currentSuite.acf.customSeoDescription
          ? currentSuite.acf.customSeoDescription
          : undefined;

      const currentSuiteMeta = {
        title: metaTitle,
        metaDesc,
      };

      return (
        <div>
          <Head metaTags={currentSuiteMeta} />
          <PageTitle
            title={currentSuite.name}
            strapline={
              currentSuite.acf ? currentSuite.acf.strapline : undefined
            }
          />
          {currentSuite && currentSuite.acf && (
            <PageContent pageContent={currentSuite.acf.pageContent} />
          )}
        </div>
      );
    }
    return <PageLoader />;
  }
}

const mapStateToProps = state => ({
  suites: state.suites.items,
  products: state.products.items,
  isFetching: state.products.isFetching && state.suites.isFetching,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchSuites,
      fetchProducts,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Suite): any);
