// @flow

import * as React from 'react';
import PageTitleIcon from './PageTitleIcon';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  title?: ?string,
  strapline?: ?string,
  resourceType?: string,
};

const PageTitle = ({ title, strapline, resourceType }: Props): React.Element<"div"> => (
  <div
    className={`page-title ${
      resourceType ? 'page-title--resource-single' : ''
    }`}
  >
    {resourceType ? (
      <div className="page-title__inner">
        <PageTitleIcon name={resourceType} colour="white" />
        <h2 className="page-title__title">{ReactHtmlParser(title || '')}</h2>
      </div>
    ) : (
      <h1>{ReactHtmlParser(title || '')}</h1>
    )}
    {strapline && (
      <p className="page-title__strapline">{ReactHtmlParser(strapline)}</p>
    )}
  </div>
);

export default PageTitle;
