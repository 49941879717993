// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  location: any,
};

class ScrollToTopOnNav extends React.Component<Props> {
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.pathname.includes('/partners/') &&
      !this.props.location.pathname.includes('/helix-personas')
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default (withRouter(ScrollToTopOnNav): any);
