// @flow strict
import * as React from 'react';
import type { IndustryCallouts as IndustryCalloutsType } from '../../types';
import ReactHtmlParser from 'html-react-parser';
import { Container, Grid } from 'semantic-ui-react';

type Props = {
  row: IndustryCalloutsType,
};

const IndustryCallouts = ({ row }: Props): React.Element<"div"> => {
  const { industryCalloutsRepeater } = row;

  let tabletWidth;
  let computerWidth;

  switch (industryCalloutsRepeater.length) {
    case 1:
      tabletWidth = 8;
      computerWidth = 8;
      break;
    case 2:
      tabletWidth = 6;
      computerWidth = 6;
      break;
    default:
      tabletWidth = 6;
      computerWidth = 4;
  }

  return (
    <div className="margin--bottom-30">
      <Container>
        <Grid stackable centered stretched>
          {industryCalloutsRepeater.map(function(callout, i) {
            return (
              <Grid.Column
                tablet={tabletWidth}
                computer={computerWidth}
                key={`industry-callout-${i}`}
              >
                <div className="callout callout--less-bottom-padding">
                  <div
                    className="callout__icon callout__icon--large"
                    style={{
                      backgroundImage: `url(${
                        callout.image ? callout.image.url : ''
                      })`,
                    }}
                  />
                  <div className="callout__title">
                    <h4 className="text--cyan">
                      {ReactHtmlParser(callout.title)}
                    </h4>
                  </div>
                  <div className="callout__content">
                    {ReactHtmlParser(callout.text)}
                  </div>
                </div>
              </Grid.Column>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default IndustryCallouts;
