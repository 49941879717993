// @flow
import * as React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import * as basicLightbox from 'basiclightbox';
import Share from '../Share';
import VideoComponent from '../Video';
import MoreResources from '../MoreResources';
import type { Resource } from '../../types';
import PageLoader from '../PageLoader';

type Props = {
  resource: Resource,
};

class Video extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    // For lightbox functionality
    const galleryItems = document.querySelectorAll('.gallery-item');

    [].forEach.call(galleryItems, elem => {
      let anchor = elem.querySelector('a');

      anchor.addEventListener('click', function(e) {
        e.preventDefault();
      });

      elem.onclick = basicLightbox.create(elem.outerHTML).show;
    });
  }
  render(): React.Element<"div"> | React.Node {
    const { resource } = this.props;
    if (!resource) {
      return <PageLoader />;
    }
    return (
      <div>
        <div className="resource-single resource-single--video">
          <Container>
            <Grid stackable centered>
              <Grid.Column tablet={12} computer={8}>
                <div className="resource-single__meta">
                  <h1 className="resource-single__title">
                    {resource.title && ReactHtmlParser(resource.title.rendered)}
                  </h1>
                  <div className="resource-single__meta-flex">
                    <div className="resource-single__meta-share">
                      <Share inline title={resource.yoast.title} />
                    </div>
                  </div>
                </div>
                <div className="resource-single__content">
                  {resource.content &&
                    ReactHtmlParser(resource.content.rendered)}
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        {resource.acf && resource.acf.video && (
          // $FlowFixMe
          <VideoComponent
            iframe={resource.acf.video}
            placeholder={
              resource.acf.videoPlaceholderImage &&
              typeof resource.acf.videoPlaceholderImage !== 'boolean'
                ? resource.acf.videoPlaceholderImage.url
                : undefined
            }
            title={resource.yoast.title}
          />
        )}
        <div className="resource-single__footer">
          <Share large title={resource.yoast.title} />
        </div>
        {resource.acf && resource.acf.moreResources && (
          <MoreResources
            title={resource.acf.moreResourcesTitle}
            resources={resource.acf.moreResources}
          />
        )}
      </div>
    );
  }
}

export default Video;
