// @flow strict
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GetStartedWithProductCTA from '../../components/PageContent/GetStartedWithProductCTA';
import { sendContact } from '../../actions';
import type { Dispatch } from '../../types';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      sendContact,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(GetStartedWithProductCTA): any);
