// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import Link from '../Link';
import InlineSvg from '../InlineSvg';
import { makeCommunityURL } from '../../helpers/makeURL';

type Props = {
  id: string,
  colour: string,
  image: string,
  title: string,
  summary: string,
  url: string,
  name: string,
};

type CommunityContentProps = {
  summary: string,
  url: string,
  inOrOut: 'inside' | 'outside',
};

const CommunitySummary = ({
  summary,
  url,
  inOrOut,
}: CommunityContentProps): any => {
  const hiddenClass = inOrOut === 'inside' ? 'content__summary--hidden' : '';

  return (
    <div
      className={`content__summary content__summary--${inOrOut} ${hiddenClass}`}
    >
      <p className="summary__text">{ReactHtmlParser(summary)}</p>
      <Link url={url} className="summary__browse no-underline" onClick={window.scrollTo({ top: 0 })}>
        BROWSE
        <InlineSvg
          className="browse__svg"
          name="arrow-right"
          colour={'currentColor'}
        />
      </Link>
    </div>
  );
};

const CommunitySummaryTile = ({
  id,
  name,
  colour,
  image,
  title,
  summary,
  url,
}: Props): React.Element<'div'> => {
  let currentUrl = window?.location?.pathname;
  let countryFromUrl = currentUrl.split('/')[2];

  const [country, setCountry] = React.useState('helix-personas');
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    if (countryFromUrl.includes('nz')) {
      setCountry('helix-personas-nz');
    } else if (countryFromUrl.includes('id')) {
      setCountry('helix-personas-id');
    } else {
      setCountry('helix-personas');
    }
  }, [countryFromUrl]);

  React.useEffect(() => {
    function makeTileSquare() {
      if (ref?.current?.clientWidth) {
        setHeight(ref?.current?.clientWidth);
      }
    }

    if (height === 0) {
      makeTileSquare();
    }

    window.addEventListener('resize', makeTileSquare);
    return () => window.removeEventListener('resize', makeTileSquare);
  }, [ref?.current?.clientWidth, height]);

  return (
    <div className="community__tile-wrapper">
      <div ref={ref} style={{ height: `${height}px` }}>
        <div className="community__tile">
          <img className="tile__image" src={image} alt={title} />
          <div className="tile__content" style={{ backgroundColor: colour }}>
            <Link
              url={`/products/${country}/personas/${makeCommunityURL(
                id,
                name
              )}`}
              className="tile__header no-underline"
            >
              <div className='tile__header-title-group'>
                <h4>
                  <span className="tile__header-id">{id}</span>
                </h4>
                <h4>
                  <span className="tile__header-name">{name}</span>
                </h4>
              </div>
            </Link>
            <CommunitySummary
              summary={summary}
              url={`/products/${country}/personas/${makeCommunityURL(
                id,
                name
              )}`}
              inOrOut="inside"
            />
          </div>
        </div>
      </div>
      <CommunitySummary
        summary={summary}
        url={`/products/${country}/personas/${makeCommunityURL(id, name)}`}
        inOrOut="outside"
      />
    </div>
  );
};

export default CommunitySummaryTile;
