// @flow
import * as React from 'react';
import OwlCarousel from 'react-owl-carousel';
import ReactHtmlParser from 'html-react-parser';
import type { Testimonials as TestimonialsType } from '../../types';

type Props = {
  row: TestimonialsType,
};

class Testimonials extends React.Component<Props> {
  render(): React.Element<"div"> {
    const { testimonialsRepeater } = this.props.row;

    return (
      <div className="page-row page-row--background-white">
        <OwlCarousel
          items={1}
          margin={20}
          nav
          navText={['', '']}
          center
          dots={false}
          autoplay={true}
          autoplayTimeout={7000}
          autoplaySpeed={500}
          autoplayHoverPause
          loop
          navContainerClass="owl-nav owl-nav--blue"
        >
          {testimonialsRepeater
            .filter(t => t.testimonial)
            .map((testimonialObject, i) => {
              const { testimonial } = testimonialObject;

              return (
                <div key={i}>
                  <div className="testimonial-item owl-item-inner--max-width">
                    {ReactHtmlParser(testimonial.postContent)}
                  </div>
                  <div className="testimonial-item__client">
                    — {ReactHtmlParser(testimonial.postTitle)}
                  </div>
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    );
  }
}
export default Testimonials;
