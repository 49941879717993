// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'semantic-ui-react';
import { fetchNZPersonas } from '../../actions';
import CommunityTile from '../CommunityTile';
import { getNZPersonasSortedAlphabetically } from '../../reducers/nz_personas';
import type { Dispatch } from '../../types';
import PageLoader from '../../components/PageLoader';

type Props = {
  fetchNZPersonas: () => Promise<*>,
  nz_personas: Object,
  clearSlug: Function,
  persona_slug?: string,
};

class HelixNZPersonaList extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchNZPersonas();
  }

  render() {
    const { nz_personas, persona_slug, clearSlug } = this.props;

    if (!nz_personas || nz_personas.length === 0) {
      return <PageLoader />;
    }

    const personaTiles = nz_personas
      ? nz_personas.map((persona, key) => {
          if (persona && persona.acf) {
            return (
              <CommunityTile
                communities={nz_personas}
                key={key}
                colour={persona.acf.color}
                image={persona.acf.featuredImage.url}
                title={persona.postTitle}
                summary={persona.acf.listingSummary}
                communityImageGallery={persona.acf.communityImageGallery}
                personas={persona.personas}
                persona_slug={persona_slug}
                clearSlug={clearSlug}
                acf={persona.acf}
                buttonLinkOverride={`/products/helix-personas-nz/${
                  persona.postName
                }-${persona.acf.title.replace(/\s+/g, '-').toLowerCase()}`}
                personaSlugOverride="helix-personas-nz"
                replaceWithBreaks={true}
                country="nz"
                communitySlug=''
              />
            );
          } else {
            return '';
          }
        })
      : [];

    return (
      <div className="page-row">
        <Container>
        <h3 className="helix-persona-list__heading">Browse Helix Personas</h3>
          <div className="community-tiles">
            <div className="community-tiles-flex">{personaTiles}</div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nz_personas: getNZPersonasSortedAlphabetically(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchNZPersonas,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(HelixNZPersonaList): any);
