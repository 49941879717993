// @flow
import type {Page} from "../types/page";
import { REQUEST_INDO_PERSONAS, RECEIVE_INDO_PERSONAS } from '../actions';
import type {
  PageState as State,
  RequestIndoPersonasAction,
  RecieveIndoPersonasAction,
} from '../types';
import { createSelector } from 'reselect';

type Action = RequestIndoPersonasAction | RecieveIndoPersonasAction;

const initialState = {
  isFetching: false,
};

export function indo_personas(state: State = initialState, action: Action): 
  | State
  | {[number]: Page, byId: any, isFetching: boolean}
  | {[number]: Page, isFetching: boolean} {
  switch (action.type) {
    case REQUEST_INDO_PERSONAS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_INDO_PERSONAS:
      return {
        ...state,
        isFetching: false,
        byId: [...action.indo_personas],
      };
    default:
      return state;
  }
}

export const getIndoPersonasSortedAlphabetically: any = createSelector(
  state => state.indo_personas.byId,
  items => {
    if (items === undefined) {
      return [];
    }
    return Object.keys(items)
      .sort(function(a, b) {
        // return items[ a ].name - items[ b ].name;
        if (
          items[a].postTitle === undefined ||
          items[b].postTitle === undefined
        ) {
          return 0;
        }
        if (
          items[a].postTitle.toLowerCase() < items[b].postTitle.toLowerCase()
        ) {
          return -1;
        } else if (
          items[a].postTitle.toLowerCase() > items[b].postTitle.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function(sortedKey) {
        return items[sortedKey];
      });
  }
);
