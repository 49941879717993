// @flow
import * as React from 'react';
import { Container } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import InlineCallbackForm from '../InlineCallbackForm';
import type {
  GetStartedWithProductCTA as ContentType,
  SendContact,
} from '../../types';

type Props = {
  row: ContentType,
  sendContact: SendContact,
};

type State = {
  success: boolean,
  error: boolean,
  submitted: boolean,
};

class GetStartedWithProductCTA extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      submitted: false,
    };
  }

  onSubmit: any = async (values: Object) => {
    try {
      this.setState({
        submitted: true,
      });
      const result = await this.props.sendContact(
        values,
        'Live Product Request Callback',
        'Product Request Callback',
        window.location.href
      );
      this.setState({
        success: result,
        error: !result,
      });
    } catch (error) {
      this.setState({
        success: false,
        error: true,
      });
    }
  };

  render(): any {
    const { row } = this.props;
    return (
      <div className={`get-started-with-product-cta__wrapper ${row.altVersion ? "get-started-with-product-cta__alt-version" : ""}`}>
        <div className="get-started-with-product-cta__rainbow-stripe">
          <div className="rainbow-stripe__dark-blue"></div>
          <div className="rainbow-stripe__blue"></div>
          <div className="rainbow-stripe__green"></div>
          <div className="rainbow-stripe__maroon"></div>
          <div className="rainbow-stripe__yellow"></div>
          <div className="rainbow-stripe__grey"></div>
        </div>
        <div className="get-started-with-product-cta">
          <Container>
            <div className="get-started-with-product-cta__grid">
              <div className="get-started-with-product-cta__content">
                <h3>Get started with </h3>
                <h1>Helix Personas</h1>
                {ReactHtmlParser(row.text)}
              </div>
              <InlineCallbackForm
                onSubmit={this.onSubmit}
                success={this.state.success}
                error={this.state.error}
                submitted={this.state.submitted}
                row={row}
                fancy={true}
              />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default GetStartedWithProductCTA;
