// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
// import Link from '../Link';
import CommunitySummaryTileStat from './CommunitySummaryTileStat';
import CommunitySummaryTileReadMoreLink from './CommunitySummaryTileReadMoreLink';

type CommunitySummaryTileProps = {
  id: string | number,
  name: string,
  colour: string,
  image: string,
  url: string,
  summary?: string,
  linkText?: string,
  isSummaryFixed?: boolean,
  stat1img?: string,
  stat1label?: string,
  stat1?: string,
  excerptNumberOnLeft1?: boolean,
  excerptNumberOnLeft2?: boolean,
  stat2img?: string,
  stat2label?: string,
  stat2?: string,
  postName?: string,
  personaCarousel: boolean,
  communityCarousel: boolean,
  accessInfo?: any,
  community?: Object,
  personaPageUrl?: String,
};

const CommunitySummaryTile = ({
  id,
  name,
  colour,
  image,
  url,
  summary,
  linkText,
  isSummaryFixed,
  stat1img,
  stat1label,
  stat1,
  excerptNumberOnLeft1,
  excerptNumberOnLeft2,
  stat2img,
  stat2label,
  stat2,
  accessInfo,
  postName,
  personaCarousel,
  communityCarousel,
}: CommunitySummaryTileProps): React.Element<typeof React.Fragment> => {
  return (
    <>
      <div className="community-summary-tile">
        <div
          className={`community-summary-tile__tile ${
            summary && !isSummaryFixed
              ? 'community-summary-tile__tile--hoverable'
              : ''
          }`}
        >
          <img className="tile__image" src={image} alt={id + ' - ' + name} />
          <div className="tile__content" style={{ backgroundColor: colour }}>
            {communityCarousel ? (
              <Link
                to={`/products/${url}`}
                className="tile__header no-underline"
                onClick={window.scrollTo({ top: 0 })}
              >
                <div className="tile__header-title-group">
                  <h4>
                    <span className="tile__header-id">{id}</span>
                  </h4>
                  <h4>
                    <span className="tile__header-name">{name}</span>
                  </h4>
                </div>
              </Link>
            ) : (
              <Link to={`${url}`} className="tile__header no-underline" onClick={window.scrollTo({ top: 0 })}>
                <div className="tile__header-title-group">
                  <h4>
                    <span className="tile__header-id">{id}</span>
                  </h4>
                  <h4>
                    <span className="tile__header-name">{name}</span>
                  </h4>
                </div>
              </Link>
            )}
            {summary && !isSummaryFixed && (
              <div className="community-summary-tile__tile-summary-content">
                <p>{summary}</p>
                <CommunitySummaryTileReadMoreLink
                  text="BROWSE"
                  url={url}
                  colour="white"
                  name={name}
                  id={id}
                />
              </div>
            )}
          </div>
        </div>
        {summary && (
          <div
            className={`community-summary-tile__summary-text ${
              !isSummaryFixed
                ? 'community-summary-tile__summary-text--mobile-only'
                : ''
            }`}
          >
            <div className="community-summary-tile__stats-summary-text-group">
              {isSummaryFixed && (stat1 || stat2) && (
                <div className="community-summary-tile__summary-text-stat-wrapper">
                  {stat1 && stat1label && stat1img && excerptNumberOnLeft1 && (
                    <CommunitySummaryTileStat
                      colour={colour}
                      stat={stat1}
                      statLabel={stat1label}
                      statImg={stat1img}
                      isNumberOnLeft={excerptNumberOnLeft1}
                    />
                  )}
                  {stat2 && stat2label && stat2img && excerptNumberOnLeft2 && (
                    <CommunitySummaryTileStat
                      colour={colour}
                      stat={stat2}
                      statLabel={stat2label}
                      statImg={stat2img}
                      isNumberOnLeft={excerptNumberOnLeft2}
                      width="18px"
                    />
                  )}
                </div>
              )}
              {/* <div className="summary__text__wrapper">
                {ReactHtmlParser(summary)}
              </div> */}
            </div>
            <CommunitySummaryTileReadMoreLink
              text={linkText}
              url={url}
              name={name}
              id={id}
              accessInfo={accessInfo}
              colour="black"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CommunitySummaryTile;
