// @flow
import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import Share from './Share';

type Props = {
  // source?: string,
  // id?: string,
  title?: ?string,
  placeholder: ?string,
  share?: boolean,
  caption?: string,
  iframe: string,
  text?: string,
};

class Video extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  openModal: (() => void) = () => {
    this.setState({
      modalOpen: true,
    });
  };

  // TODO (CW): Look into video URL.
  render(): React.Element<"div"> {
    const { iframe, placeholder, share, text, title } = this.props;

    return (
      <div className="page-row page-row--multifaceted-grey-bg">
        <div className="video-placeholder">
          {placeholder && (
            <img
              src={placeholder}
              alt=""
              className="video-placeholder__image"
            />
          )}
          <Modal
            trigger={
              <div className="video-placeholder__play-button-container">
                <button className="video-placeholder__play-button" />
              </div>
            }
            basic
            className="video-modal"
          >
            {ReactHtmlParser(iframe)}
          </Modal>
        </div>
        {share && <Share title={title ? title : undefined} />}
        {text && (
          <div className="video-row__caption">{ReactHtmlParser(text)}</div>
        )}
      </div>
    );
  }
}

export default Video;
