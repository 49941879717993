// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Head from '../components/Head';
import { fetchBundle } from '../actions';
import PageLoader from '../components/PageLoader';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';
import { withRouter } from 'react-router-dom';
import type { DefaultPage as DefaultPageType, Dispatch } from '../types';

type Props = {
  bundles: any,
  page: DefaultPageType,
  match: {
    params: {
      slug: string,
      persona_slug: string,
    },
  },
  history: any,
  fetchBundle: (slug: string) => Promise<void>,
  isFetching: boolean,
};

class Product extends React.Component<Props> {
  componentDidMount() {
    const { slug } = this.props.match.params;
    const { fetchBundle, page } = this.props;
    if (!page || !page.acf) {
      fetchBundle(slug);
    }
  }

  render() {
    const { slug } = this.props.match.params;
    const { bundles, isFetching } = this.props;

    const currentBundle = bundles && bundles[slug];

    if (!currentBundle || !currentBundle.acf || isFetching) {
      return <PageLoader />;
    }

    return (
      <div>
        <Head metaTags={currentBundle.yoast} />
        <PageTitle title={currentBundle.title.rendered} />
        <PageContent pageContent={currentBundle.acf.pageContent} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bundles: state.bundles,
    isFetching: state.bundles.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchBundle,
    },
    dispatch
  );
};

export default (withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Product)
): React.AbstractComponent<Props>);
