// @flow
import * as React from 'react';
import CommunitySummaryTile from './CommunitySummaryTile';
import Slider from 'react-slick';
import { makeCommunityURL } from '../helpers/makeURL';

const CommunityCarousel = ({
  fullWidth = false,
  personas,
  communityCarousel,
  countryUrl,
  welcomePage: {
    acf: {
      // $FlowFixMe
      browseCommunitySubtitle,
      // $FlowFixMe
      browseCommunityText,
    },
  },
}: {
  personas: Object,
  fullWidth: any,
  welcomePage: any,
  countryUrl: any,
  communityCarousel: any,
}): React.Element<'div'> => {
  // const communities = getStaticPersonas();
  const settings = {
    arrows: false,
    className: 'slider-custom',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div
      className={
        fullWidth
          ? 'community-carousel community-carousel-full-width'
          : 'community-carousel'
      }
    >
      <div className="community-carousel__head-mobile">
        <h2>{browseCommunitySubtitle}</h2>
        <p>{browseCommunityText}</p>
      </div>
      <div className="community-carousel__wrapper">
        <div className="community-carousel__head">
          <h2>{browseCommunitySubtitle}</h2>
          <p>{browseCommunityText}</p>
        </div>
        <div className="community-carousel__items-wrapper">
          <Slider {...settings}>
            {personas && personas.map((persona, i) => (
              <CommunitySummaryTile
                key={i}
                id={persona?.slug}
                name={persona?.acf?.title}
                linkText={'BROWSE'}
                colour={persona?.acf?.color}
                image={
                  persona?.acf?.featuredImage?.url
                    ? persona?.acf?.featuredImage?.url
                    : persona?.acf?.communityImageGallery[0]?.url
                    ? persona?.acf?.communityImageGallery[0]?.url
                    : ""
                }
                title={persona?.postTitle}
                url={`${countryUrl}/${makeCommunityURL(
                  persona?.slug,
                  persona?.acf?.title
                )}`}
                personaCarousel={false}
                communityCarousel={communityCarousel}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CommunityCarousel;
