// @flow

import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Image } from 'semantic-ui-react';
import rmrLiveLogo from '../assets/images/logos/rmr_live_logo_v2.png';
import ProductsMegaMenu from './ProductsMegaMenu';
import LoginLinksMegaMenu from './LoginLinksMegaMenu';
import ProductNavItem from './NavItems/ProductNavItem';
import LoginNavItem from './NavItems/LoginNavItem';
import { premiumLink } from '../helpers';

type Props = {};

type State = {
  navMenuOpen: boolean,
  productsMegaMenuOpen: boolean,
  loginLinksMegaMenuOpen: boolean,
  productsMouseOut: boolean,
  menuMouseOut: boolean,
};

class NavMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      navMenuOpen: false,
      productsMegaMenuOpen: false,
      loginLinksMegaMenuOpen: false,
      productsMouseOut: true,
      menuMouseOut: true,
    };
  }

  singleProducts: Array<{| strapline: string, title: string, url: string |}> =[
    {
      title: 'Helix Personas',
      strapline: 'Predicting consumer behaviour',
      url: '/products/helix-personas',
    },
    {
      title: 'Helix Personas NZ',
      strapline: 'Predicting consumer behaviour',
      url: '/products/helix-personas-nz',
    },
    {
      title: 'Helix Personas Indonesia',
      strapline: 'Predicting consumer behaviour',
      url: '/products/helix-personas-id'
    },
    {
      title: 'Live Mapping',
      strapline: 'Identify hotspots of consumer demand',
      url: '/products/live-mapping',
    },
    {
      title: 'Live Search',
      strapline:
        'Discover and quantify potential audiences in just a few clicks',
      url: '/products/live-search',
    },
    {
      title: 'Premium',
      strapline: 'Meet the future shapers',
      url: '/products/premium',
    },
  ];

singleLoginLinks: Array < {| strapline: string, title: string, url: string |}> =[
  {
    title: 'Helix Personas',
    strapline: 'Login to Helix Personas',
    url: 'https://helix.roymorgan.com'
  },
  {
    title: 'Helix Personas NZ',
    strapline: 'Login to Helix Personas NZ',
    url: 'https://nzhelix.roymorgan.com'
  },
  {
    title: 'Helix Personas Indonesia',
    strapline: 'Login to Helix Personas Indonesia',
    url: 'https://idhelix.roymorgan.com'
  },
  {
    title: 'Live Mapping',
    strapline: 'Login to Live Mapping',
    url: 'https://mapping.roymorgan.com'
  },
  {
    title: 'Live Search',
    strapline: 'Login to Live Search',
    url: 'https://search.roymorgan.com'
  },
  {
    title: 'Live Audience Evaluation',
    strapline: 'Login to Live Audience Evaluation',
    url: 'https://audiences.roymorgan.com'
  },
  {
    title: 'Premium',
    strapline: 'Login to Premium',
    url: premiumLink(),
  },
];

onToggleNavMenu: (() => void) = () => {
  this.setState({
    navMenuOpen: !this.state.navMenuOpen,
  });
};

onNavigate: (() => void) = () => {
  this.setState({
    navMenuOpen: false,
    productsMegaMenuOpen: false,
    loginLinksMegaMenuOpen: false,
    productsMouseOut: true,
    menuMouseOut: true,
  });
};

onProductsClick: ((e: SyntheticEvent<any>) => void) = (e: SyntheticEvent<any>) => {
  e.preventDefault();
  setTimeout(
    function () {
      if (this.state.productsMegaMenuOpen) {
        this.setState({
          productsMegaMenuOpen: false,
        });
      } else {
        this.setState({
          productsMegaMenuOpen: true,
          loginLinksMegaMenuOpen: false,
        });
      }
    }.bind(this),
    100
  );
};

onLoginLinksClick: ((e: SyntheticEvent<any>) => void) = (e: SyntheticEvent<any>) => {
  e.preventDefault();
  setTimeout(
    function () {
      if (this.state.loginLinksMegaMenuOpen) {
        this.setState({
          loginLinksMegaMenuOpen: false,
        });
      } else {
        this.setState({
          loginLinksMegaMenuOpen: true,
          productsMegaMenuOpen: false,
        });
      }
    }.bind(this),
    100
  );
};

onMenuMouseOver: (() => void) = () => {
  this.setState({
    menuMouseOut: false,
  });
};

onMenuMouseLeave: (() => void) = () => {
  this.setState({
    menuMouseOut: true,
  });
};

render(): React.Element < "div" > {
  return(
      <div>
        <div
          onMouseOver={this.onMenuMouseOver}
          onFocus={this.onMenuMouseOver}
          onMouseLeave={this.onMenuMouseLeave}
          onBlur={this.onMenuMouseLeave}
        >
          <Menu
            borderless={true}
            fixed="top"
            stackable
            className={`nav-menu ${
              this.state.navMenuOpen ? 'nav-menu--open' : ''
            } ${
              this.state.productsMegaMenuOpen ||
              this.state.loginLinksMegaMenuOpen
                ? 'nav-menu--mega-menu-open'
                : ''
            }`}
          >
            <Menu.Item header fitted className="nav-menu__header">
              <button
                className="nav-menu__menu-button hide-for-small"
                onClick={this.onToggleNavMenu}
              >
                <div className="nav-menu__menu-button-label" />
              </button>
              <Image
                src={rmrLiveLogo}
                as={Link}
                to="/"
                className="nav-menu__logo-link"
                onClick={this.onNavigate}
              />
            </Menu.Item>
            <ProductNavItem
              productsMegaMenuOpen={this.state.productsMegaMenuOpen}
              onProductsClick={this.onProductsClick}
              onNavigate={this.onNavigate}
            />
            <Menu.Item
              as={NavLink}
              to="/about"
              name="about"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              onClick={this.onNavigate}
            />
            <Menu.Item
              as={NavLink}
              to="/resources"
              name="resources"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              onClick={this.onNavigate}
            />
            <Menu.Item
              as={NavLink}
              to="/partners"
              name="partners"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              onClick={this.onNavigate}
            />
            <Menu.Item
              as={NavLink}
              to="/media"
              name="media"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              onClick={this.onNavigate}
            />
            <LoginNavItem
              loginLinksMegaMenuOpen={this.state.loginLinksMegaMenuOpen}
              onLoginLinksClick={this.onLoginLinksClick}
            />
            <Menu.Item
              as={NavLink}
              to="/contact-us"
              name="contact"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              onClick={this.onNavigate}
            />
          </Menu>
          <ProductsMegaMenu
            onNavigate={this.onNavigate}
            megaMenuOpen={this.state.productsMegaMenuOpen}
            singleLinks={this.singleProducts}
          />
          <LoginLinksMegaMenu
            onNavigate={this.onNavigate}
            megaMenuOpen={this.state.loginLinksMegaMenuOpen}
            singleLinks={this.singleLoginLinks}
          />
        </div>
        <div
          id="nav-menu-page-overlay"
          className={
            this.state.navMenuOpen ||
            this.state.productsMegaMenuOpen ||
            this.state.loginLinksMegaMenuOpen
              ? 'nav-menu-page-overlay--open'
              : ''
          }
        />
      </div >
    );
  }
}
export default NavMenu;
