// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import type { Page, Dispatch, ThunkAction } from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_BUNDLE = 'REQUEST_BUNDLE';
function requestPage(): any {
  return {
    type: REQUEST_BUNDLE,
  };
}

export const RECEIVE_BUNDLE = 'RECEIVE_BUNDLE';
function receivePage(pageId, json: Page): any {
  return {
    type: RECEIVE_BUNDLE,
    pageId,
    page: json,
  };
}

export function fetchBundle(pageSlug: string): ThunkAction {
  return function(dispatch: Dispatch) {
    dispatch(requestPage());
    return fetch(`${API_BASE}/wp-json/wp/v2/pages?slug=${pageSlug}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(
        response => (response.json(): Promise<Object>),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        const camelizedJson = (camelizeKeys(json, noCamelize): Page);
        return dispatch(receivePage(pageSlug, camelizedJson));
      });
  };
}
