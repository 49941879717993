// @flow
import { REQUEST_SEGMENTS, RECEIVE_SEGMENTS } from '../actions';
import type { RequestSegmentsAction, ReceiveSegmentsAction } from '../types';

type State = {
  isFetching: boolean,
  byId: Array<any>,
};

type Action = RequestSegmentsAction | ReceiveSegmentsAction;

const initialState = {
  isFetching: false,
  byId: [],
};

export function segments(state: State = initialState, action: Action): State {
  switch (action.type) {
    case REQUEST_SEGMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_SEGMENTS:
      return {
        ...state,
        isFetching: false,
        byId: [...action.segments],
      };
    default:
      return state;
  }
}
