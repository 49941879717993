// @flow
import type {Page} from "../types/page";import { REQUEST_BUNDLE, RECEIVE_BUNDLE } from '../actions';
import type { PageState as State } from '../types';

type Action = Action;

const initialState = {
  isFetching: false,
};

function bundles(state: State = initialState, action: Action): State | {[number]: Page, isFetching: boolean} {
  switch (action.type) {
    case REQUEST_BUNDLE:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_BUNDLE:
      return {
        ...state,
        isFetching: false,
        [action.pageId]: {
          ...action.page[0],
        },
      };
    default:
      return state;
  }
}

export default bundles;
