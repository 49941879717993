// @flow strict
import React from 'react';
import { getStaticPersonas } from '../../helpers/personas';
import CommunitySummaryTile from './CommunitySummaryTile';
import Link from '../Link';
import { Grid } from 'semantic-ui-react';
import { Container } from 'semantic-ui-react';
import type { CommunitySummariesType } from '../../types';

type Props = {
  row: CommunitySummariesType,
};

const CommunitySummaries = ({ row }: Props): any => {
  // fetch hardcoded community data
  const communities = getStaticPersonas();

  const communityItems = communities
    ? communities.map((community, key) => (
        <CommunitySummaryTile
          key={key}
          id={community.id}
          name={community.name}
          colour={community.colour}
          image={community.img}
          title={community.id + ' - ' + community.name}
          summary={community.summary}
          url={`/${community.id}/`}
        />
      ))
    : [];

  return (
    <div className="community-summaries">
      <Container>
        <div
          className={`community-summaries__grid ${
            communities.length === 7 ? 'community-tiles-flex--nz' : ''
          }`}
        >
          <div className="community-summaries__main">
            <h2>{row?.title}</h2>
            <p>{row?.intro}</p>
            <div className="community-summaries__button-grid">
              {row?.buttonRepeater &&
                row?.buttonRepeater.map((button, i) => {
                  // rewrites the personas listing page slug as a child of the current page - helps handle the product page that renders this being used here and in the helix marketing site
                  const href =
                    button?.button?.url === '/personas'
                      ? window.location.pathname + '/personas'
                      : button?.button?.url;
                  return (
                    <Grid.Column
                      as={Link}
                      target={button?.button?.target}
                      url={href}
                      key={i}
                      className="button"
                    >
                      {button?.button?.title}
                    </Grid.Column>
                  );
                })}
            </div>
          </div>
          {communityItems}
        </div>
      </Container>
    </div>
  );
};

export default CommunitySummaries;
