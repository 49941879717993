// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Grid, Container, Button } from 'semantic-ui-react';
import Link from '../Link';
import type { LeadRow as ContentType } from '../../types';

type Props = {
  row: ContentType,
};

const LeadRow = ({ row }: Props): React.Element<"div"> => {
  const { title, text, link } = row;

  let rowPaddingClass;

  link && link.url !== ''
    ? (rowPaddingClass = '')
    : (rowPaddingClass = 'page-row--extra-bottom-padding');

  return (
    <div className={`page-row  ${rowPaddingClass} page-row--background-white`}>
      <h2 className={`text--cyan`}>{ReactHtmlParser(title)}</h2>
      <Container>
        <Grid centered stackable>
          <Grid.Column tablet={10} computer={8}>
            <div className="lead-row">{ReactHtmlParser(text)}</div>
            {link && link.url && (
              <div className="page-row__buttons">
                {link && (
                  <Button
                    as={Link}
                    target={link.target}
                    url={link.url}
                    color="blue"
                    size="large"
                  >
                    {ReactHtmlParser(link.title)}
                  </Button>
                )}
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default LeadRow;
