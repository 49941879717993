// @flow
import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'semantic-ui-react';
import Link from './Link';

type Props = {
  row: Object,
};

const PersonaListingHeader = ({ row }: Props): any => {
  const {
    personaListingPageTitle,
    personaListingIntroText,
    personaListingButtonRepeater,
    personaListingHeaderImg1,
    personaListingHeaderImg2,
    personaListingHeaderImg3,
  } = row;

  const [height, setHeight] = useState(0);
  const tileRef = useRef();

  useEffect(() => {
    if (!height && tileRef?.current?.offsetWidth) {
      setHeight(tileRef?.current?.offsetWidth);
    }
  }, [height]);

  useEffect(() => {
    const onResize = () => {
      if (tileRef?.current?.offsetWidth) {
        setHeight(tileRef?.current?.offsetWidth);
      }
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <div className="persona-listing-header__wrapper">
      <div className="persona-listing-header__outer-container-grid">
        <div className="persona-listing-header__mosaic-grid">
          <div className="persona-listing-header__mosaic">
            <div
              ref={tileRef}
              style={{ height: `${height}px` }}
              className={`${'persona-listing-header__mosaic-block'} ${
                !personaListingHeaderImg1 ? 'solid-color' : ''
              }`}
            >
              <img
                className={`${'mosaic-block-image'}`}
                src={personaListingHeaderImg1}
                alt="persona listing header"
              />
            </div>
            <div
              style={{ height: `${height}px` }}
              className="persona-listing-header__mosaic-block green-mosaic-block"
            ></div>
            <div
              style={{ height: `${height}px` }}
              className="persona-listing-header__mosaic-block white-mosaic-block"
            ></div>
            <div
              style={{ height: `${height}px` }}
              className={`${'persona-listing-header__mosaic-block'} ${
                !personaListingHeaderImg2 ? 'solid-color' : ''
              }`}
            >
              <img
                className="mosaic-block-image"
                src={personaListingHeaderImg2}
                alt="persona listing header"
              />
            </div>
            <div
              style={{ height: `${height}px` }}
              className="persona-listing-header__mosaic-block white-mosaic-block"
            ></div>
            <div
              style={{ height: `${height}px` }}
              className={`${'persona-listing-header__mosaic-block'} ${
                !personaListingHeaderImg3 ? 'solid-color' : ''
              }`}
            >
              <img
                className="mosaic-block-image"
                src={personaListingHeaderImg3}
                alt="persona listing header"
              />
            </div>
            <div
              style={{ height: `${height}px` }}
              className="persona-listing-header__mosaic-block blue-mosaic-block"
            ></div>
            <div
              style={{ height: `${height}px` }}
              className="persona-listing-header__mosaic-block grey-mosaic-block"
            ></div>
          </div>
        </div>
        <Container>
          <div className="persona-listing-header__content-grid">
            <div className="persona-listing-header__content--container">
              {personaListingPageTitle ? (
                <h1>{personaListingPageTitle}</h1>
              ) : null}
              {personaListingIntroText ? (
                <p>{personaListingIntroText}</p>
              ) : null}
              <div className="persona-listing-header__button-group">
                {personaListingButtonRepeater &&
                  personaListingButtonRepeater.map((button, i) => {
                    return (
                      <Link
                        key={i}
                        url={button?.button?.url}
                        target={button?.button?.target}
                        className="button"
                      >
                        {button?.button?.title}
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PersonaListingHeader;
