// @flow
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'roy-morgan-base-theme/dist/semantic.min.css';
import 'babel-polyfill';
import * as React from 'react';
import { hydrate, render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './containers/Root';
import configureStore from './store/configureStore';
// $FlowFixMe
import './assets/scss/index.scss';
import * as serviceWorker from './serviceWorker';
import type { Store } from './types';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-MSMXSV'
}
 
TagManager.initialize(tagManagerArgs)


// Get RM environment to pass through to Sentry
const env = process.env.REACT_APP_RM_ENV || '';

// Don't run in local development.
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://d807d7e850254766afbf377852f4570f@o379043.ingest.sentry.io/5207439',
    environment: env,
  });
}

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// Create Redux store with initial state
// $FlowFixMe
const store: Store = configureStore(preloadedState || {});

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState(),
});

function App() {
  return (
    <Router>
      <Root store={store} />
    </Router>
  );
}

const rootElement = document.getElementById('root');

if (rootElement) {
  if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
  } else {
    render(<App />, rootElement);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
