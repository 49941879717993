// @flow
import type { Suite, Product } from '../types';
export * from './premiumLink';
export * from './products';
export * from './liveLink';
export * from './formValidations';
export * from './api';

export const trunc = (content: string, length: number): string => {
  return content.length > length
    ? content.substr(0, length - 1) + '&hellip;'
    : content;
};

const cutString = (s, n): string => {
  var cut = s.indexOf('. ', n);
  if (cut === -1) return s;
  return s.substring(0, cut) + '.';
};

export const truncatePost = (
  content: string,
  length: 'short' | 'medium' | 'long' = 'short'
): ?string => {
  const shortLength = 300;
  const mediumLength = 500;
  const longLength = 700;

  if (typeof content === 'string' && content.length > 0) {
    switch (length) {
      case 'short':
        return cutString(content, shortLength);

      case 'medium':
        return cutString(content, mediumLength);

      case 'long':
        return cutString(content, longLength);

      default:
        return cutString(content, shortLength);
    }
  } else {
    return undefined;
  }
};

export const createExcerptIfNotDefined = (
  excerpt: string | '',
  post: string
): string => {
  if (excerpt.length === 0) {
    return trunc(post, 500);
  } else {
    return excerpt;
  }
};

export const findById = (
  workingArray: ?Array<Suite> | ?Array<Product>,
  idToFind: number,
  key: string = 'id'
): ?Suite | ?Product => {
  if (workingArray) {
    for (let i = 0, len = workingArray.length; i < len; i++) {
      if (workingArray[i][key] === idToFind) {
        return workingArray[i];
      }
    }
  }
  return undefined;
};

export const findBySlug = (
  workingArray: Object,
  slugToFind: number | string,
  key: string = 'slug'
): any => {
  if (workingArray) {
    for (let i = 0, len = workingArray.length; i < len; i++) {
      if (workingArray[i][key] === slugToFind) {
        return workingArray[i];
      }
    }
  }
  return undefined;
};

export const noCamelize = (key: string, convert: (k: string) => string): string => {
  if (key.toLowerCase() === 'id') {
    return 'id';
  }
  return /(^[A-Z0-9_]+$|^id+$)/.test(key) ? key : convert(key);
};

/**
 * Check if url is absolute or relative.
 * @param {string} url
 * @returns {boolean}
 */
export const isUrlAbsolute = (url: string): boolean =>
  url.indexOf('://') > 0 || url.indexOf('//') === 0;

const isBrowser = () => typeof window !== 'undefined';

export const getWidth = (): any | number => {
  if (isBrowser()) return window.innerWidth;
  return 1000;
};
