// @flow

export const isNZHelix = (): boolean =>
  window.location.pathname.includes('-nz')
    ? true
    : false;

export const isIndoHelix = (): boolean =>
  window.location.pathname.includes('-id')
    ? true
    : false;

export const getHelixBaseUrl = (country: string): string => {
  if (country === 'nz') {
    return 'nzhelix.roymorgan.com';
  }
  if (country === 'id') {
    return 'idhelix.roymorgan.com';
  }
  return 'helix.roymorgan.com';
};
