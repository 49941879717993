// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeroBanner from '../components/HeroBanner';
import PageContent from '../components/PageContent';
import Head from '../components/Head';
import { fetchPage } from '../actions';
import PageLoader from '../components/PageLoader';
import type { HomePage, State, Dispatch } from '../types';

const PAGE_ID = 5;

type Props = {
  page: ?HomePage,
  isFetching: boolean,
  fetchPage: (i: number) => Promise<void>,
};

class Home extends React.Component<Props, *> {
  componentDidMount() {
    const { fetchPage, page } = this.props;

    if (!page || !page.acf) {
      fetchPage(PAGE_ID);
    }
  }

  render() {
    const { page, isFetching } = this.props;

    if (!page || !page.acf || isFetching) {
      return <PageLoader />;
    }

    return (
      <div>
        <Head metaTags={page.yoast} />
        <HeroBanner
          title={page.acf.heroTitle}
          subtitle={page.acf.heroSubtitle}
          link={page.acf.heroLink}
          video={page.acf.heroVideo}
          videoButtonText={page.acf.heroVideoButtonText}
        />
        <PageContent pageContent={page.acf.pageContent} />
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    page: state.pages[PAGE_ID],
    isFetching: state.pages.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchPage,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Home): any);
