// @flow
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import * as T from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_PARTNERS = 'REQUEST_PARTNERS';
function requestPartners(): T.Action {
  return {
    type: REQUEST_PARTNERS,
  };
}

export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS';
function receivePartners(json: Array<T.Partner>): T.Action {
  return {
    type: RECEIVE_PARTNERS,
    partners: json,
  };
}

export function fetchPartners(): T.ThunkAction {
  return function(dispatch): T.Dispatch {
    dispatch(requestPartners());
    // $FlowFixMe
    return fetch(`${API_BASE}/wp-json/wp/v2/partners?per_page=100`)
      .then(
        response => (response.json(): Promise<Array<Object>>),
        (error: Error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        const camelizedJson: Array<T.Partner> = camelizeKeys(json, noCamelize);
        return dispatch(receivePartners(camelizedJson));
      });
  };
}

export const REQUEST_CHANNELS = 'REQUEST_CHANNELS';
function requestChannels(): T.Action {
  return {
    type: REQUEST_CHANNELS,
  };
}

export const RECEIVE_CHANNELS = 'RECEIVE_CHANNELS';
function receiveChannels(json: Array<T.Channel>): T.Action {
  return {
    type: RECEIVE_CHANNELS,
    channels: json,
  };
}

export function fetchChannels(): T.ThunkAction {
  return function(dispatch): T.Dispatch {
    dispatch(requestChannels());
    // $FlowFixMe
    return fetch(`${API_BASE}/wp-json/wp/v2/channel?per_page=100`)
      .then(
        response => (response.json(): Promise<Array<Object>>),
        error => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.error('An error occured.', error);
        }
      )
      .then(json => {
        const camelizedJson: Array<T.Channel> = camelizeKeys(json, noCamelize);
        return dispatch(receiveChannels(camelizedJson));
      });
  };
}
