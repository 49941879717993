// @flow
import * as React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import type { ExtendedContactInformation as PageContentType } from '../../types';
import InlineSvg from '../InlineSvg';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: PageContentType,
};

class ExtendedContactInformation extends React.Component<Props> {
  render(): React.Element<"div"> {
    const countries = this.props.row.countryRepeater;
    return (
      <div>
        <div className="page-row">
          <h2 className="page-row__title text--cyan">
            <span
              id="visit-us"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Visit Us
            </span>
          </h2>
          <Container>
            <Grid
              stackable
              className="visit-us"
              stretched
              centered
              textAlign="center"
            >
              {countries.map((country, i) => {
                return country.locationRepeater.map(
                  (location, i, locations) => {
                    return (
                      <Grid.Column
                        key={i}
                        computer={6}
                        largeScreen={3}
                        tablet={6}
                      >
                        <div className="callout visit-us__callout">
                          {location.locationName && (
                            <h4 className="visit-us__location-heading">
                              {location.locationName}
                            </h4>
                          )}
                          {location.locationEmail && (
                            <p className="visit-us__icon-text">
                              <span className="visit-us__icon">
                                <InlineSvg name="email" />
                              </span>
                              <a
                                href={`mailto:${location.locationEmail}`}
                                id={`${country.countryName.toLowerCase()}-${location.locationName.toLowerCase()}-email`}
                              >
                                {location.locationEmail}
                              </a>
                            </p>
                          )}
                          {location.phoneNumber && (
                            <p className="visit-us__icon-text">
                              <span className="visit-us__icon">
                                <InlineSvg name="phone" />
                              </span>
                              <a
                                href={`tel:${location.phoneNumber}`}
                                id={`${country.countryName.toLowerCase()}-${location.locationName.toLowerCase()}-tel`}
                              >
                                {location.phoneNumber}
                              </a>
                            </p>
                          )}
                          {location.googleMapsLink.length > 0 &&
                            location.address.length > 0 && (
                              <div className="visit-us__icon-text">
                                <span className="visit-us__icon">
                                  <InlineSvg name="location" />
                                </span>
                                <a
                                  href={location.googleMapsLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {ReactHtmlParser(location.address)}
                                </a>
                              </div>
                            )}
                          {location.googleMapsLink.length === 0 &&
                            location.address.length > 0 && (
                              <div className="visit-us__icon-text">
                                <span className="visit-us__icon">
                                  <InlineSvg name="location" />
                                </span>
                                {ReactHtmlParser(location.address)}
                              </div>
                            )}
                        </div>
                      </Grid.Column>
                    );
                  }
                );
              })}
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default ExtendedContactInformation;
