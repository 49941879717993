// @flow
import * as React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import Link from './Link';
import InlineSvg from './InlineSvg';

type Props = {
  title: string,
  subtitle?: string,
  link?: {
    target: string,
    title: string,
    url: string,
  },
  video: string,
  videoButtonText: string,
};

const HeroBanner = ({
  title,
  subtitle,
  link,
  video,
  videoButtonText,
}: Props): React.Element<"div"> => (
  <div className="hero-banner">
    <div className="hero-banner__content">
      <h1 className="hero-banner__title">{ReactHtmlParser(title)}</h1>
      {subtitle && (
        <p className="hero-banner__subtitle">{ReactHtmlParser(subtitle)}</p>
      )}
      <div className="hero-banner__buttons">
        {video && videoButtonText && (
          <div>
            <Modal
              trigger={
                <Button
                  size="large"
                  className="hero-banner__video-button button--left-icon-play"
                >
                  {ReactHtmlParser(videoButtonText)}
                </Button>
              }
              basic
              className="video-modal"
            >
              {ReactHtmlParser(video)}
            </Modal>
          </div>
        )}
        {link && (
          <div>
            <Button
              as={Link}
              url={link.url}
              inverted
              size="large"
              className="button--svg-icon"
            >
              {ReactHtmlParser(link.title)}
              <InlineSvg name="phone" />
            </Button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default HeroBanner;
