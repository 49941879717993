// @flow
import type {Node} from "React";import React from 'react';
import { Menu, Responsive } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { getWidth } from '../../helpers';

type Props = {
  productsMegaMenuOpen: boolean,
  onNavigate: Function,
  onProductsClick: Function,
};

function ProductNavItem({
  productsMegaMenuOpen,
  onNavigate,
  onProductsClick,
}: Props): Node {
  return (
    <>
      <Responsive as={React.Fragment} minWidth={768} getWidth={getWidth}>
        <Menu.Item
          as={NavLink}
          to="/products"
          name="products"
          className={`nav-menu__nav-link ${
            productsMegaMenuOpen
              ? 'nav-menu__nav-link--active nav-menu__nav-link--mega-active'
              : ''
          }`}
          activeClassName="nav-menu__nav-link--active"
          onClick={onProductsClick}
        >
          Products
          <span className="nav-menu__dropdown-icon" />
          <span className="nav-menu__dropdown-icon nav-menu__dropdown-icon--active" />
        </Menu.Item>
      </Responsive>
      <Responsive as={React.Fragment} maxWidth={767} getWidth={getWidth}>
        <Menu.Item
          as={NavLink}
          to="/products"
          name="products"
          className="nav-menu__nav-link"
          activeClassName="nav-menu__nav-link--active"
          onClick={onNavigate}
        />
      </Responsive>
    </>
  );
}

export default ProductNavItem;
