// @flow

import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';
import Share from '../Share';
import type { VideoRow as PageContentType } from '../../types';

type Props = {
  // source?: string,
  // id?: string,
  placeholder?: string,
  title?: string,
  share?: boolean,
  caption?: string,
  row: PageContentType,
  iframe: string,
};

class VideoRow extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  openModal: (() => void) = () => {
    this.setState({
      modalOpen: true,
    });
  };

  // TODO (CW): Look into video URL.
  render(): React.Element<"div"> {
    const {
      iframe,
      row: { title, shareLink = false, text, videoPlaceholder },
    } = this.props;

    return (
      <div className="page-row page-row--multifaceted-grey-bg">
        {title && (
          <h2 className="video-row__title text--cyan">
            {ReactHtmlParser(title)}
          </h2>
        )}
        <div className="video-placeholder">
          {videoPlaceholder && (
            <img
              src={videoPlaceholder}
              alt=""
              className="video-placeholder__image"
            />
          )}
          <Modal
            trigger={
              <div className="video-placeholder__play-button-container">
                <button className="video-placeholder__play-button" />
              </div>
            }
            basic
            className="video-modal"
          >
            {ReactHtmlParser(iframe)}
          </Modal>
        </div>
        {shareLink && <Share />}
        {text && (
          <div className="video-row__caption">{ReactHtmlParser(text)}</div>
        )}
      </div>
    );
  }
}

export default VideoRow;
