// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'semantic-ui-react';
import { fetchPersonas } from '../../actions';
import CommunityTile from '../CommunityTile';
import { getPersonasSortedAlphabetically } from '../../reducers/personas';
import type { Dispatch } from '../../types';
import PageLoader from '../../components/PageLoader';

type Props = {
  fetchPersonas: (helixCountry: string) => Promise<*>,
  personas: Object,
  clearSlug: Function,
  persona_slug?: string,
};

class HelixPersonaList extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchPersonas('helix');
  }

  render() {
    const { personas, persona_slug, clearSlug } = this.props;

    if (!personas || personas.length === 0) {
      return <PageLoader />;
    }

    const personaTiles = personas
      ? personas.map((persona, key) => {
          if (persona && persona.acf) {
            return (
              <CommunityTile
                key={key}
                colour={persona.acf.color}
                image={persona.acf.featuredImage.url}
                title={persona.postTitle}
                summary={persona.acf.listingSummary}
                communityImageGallery={persona.acf.communityImageGallery}
                personas={persona.personas}
                persona_slug={persona_slug}
                clearSlug={clearSlug}
                acf={persona.acf}
                country="au"
                replaceWithBreaks={true}
                communitySlug=""
                communities={{}}
              />
            );
          } else {
            return '';
          }
        })
      : [];

    return (
      <div className="page-row">
        <Container>
          <h3 className="helix-persona-list__heading">Browse Helix Personas</h3>
          <div className="community-tiles">
            <div className="community-tiles-flex">{personaTiles}</div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personas: getPersonasSortedAlphabetically(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchPersonas,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(HelixPersonaList): any);
