// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Head from '../components/Head';
import { fetchPage } from '../actions';
import PageLoader from '../components/PageLoader';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';
import type { DefaultPage as DefaultPageType, State, Dispatch } from '../types';

const pageId = 642;

type Props = {
  page: DefaultPageType,
  isFetching: boolean,
  fetchPage: (i: number) => Promise<void>,
};

class About extends React.Component<Props> {
  componentDidMount() {
    const { fetchPage, page } = this.props;

    if (!page || !page.acf) {
      fetchPage(pageId);
    }
  }

  render() {
    const { page, isFetching } = this.props;

    if (!page || !page.acf || isFetching) {
      return <PageLoader />;
    }

    return (
      <div>
        <Head metaTags={page.yoast} />
        <PageTitle title="Privacy Policy" />
        <PageContent pageContent={page.acf.pageContent} />
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    page: state.pages[pageId],
    isFetching: state.pages.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchPage,
    },
    dispatch
  );
};
export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(About): any);
