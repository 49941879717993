// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'semantic-ui-react';
import { fetchSegments } from '../../actions';
import CommunityTile from '../CommunityTile';
import type { Dispatch } from '../../types';
import { premiumLink } from '../../helpers';

type Segment = {
  acf: {
    colour: string,
    featureImage: {
      url: string,
    },
  },
  postName: string,
  postExcerpt: string,
  postTitle: string,
};

type Props = {
  fetchSegments: () => Promise<*>,
  segments: Array<Segment>,
  clearSlug: Function,
  row: {
    title: string,
  },
};

class PremiumSegmentList extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchSegments();
  }

  render() {
    const { segments, clearSlug, row } = this.props;
    const BASE_URL = premiumLink();

    const segmentTiles = segments
      ? Object.values(segments).map((segment: any, key) => {
          if (segment && segment.acf) {
            return (
              <CommunityTile
                key={key}
                colour={segment.acf.colour}
                image={segment.acf.featureImage.url}
                title={segment.postTitle}
                summary={segment.postExcerpt}
                clearSlug={clearSlug}
                acf={segment.acf}
                buttonLinkOverride={
                  segment.acf.segmentInfoPage &&
                  segment.acf.segmentInfoPage !== ''
                    ? segment.acf.segmentInfoPage
                    : `${BASE_URL}/profiles/${segment.postName}`
                }
                buttonLinkExternal={false}
                hideReadMore={true}
                country="premium"
                communitySlug={""}
                communities={{}}
              />
            );
          } else {
            return '';
          }
        })
      : [];

    return (
      <div className="page-row">
        <Container>
          <h2 className="browse-helix-personas__header">{row && row.title}</h2>
          <div className="community-tiles">
            <div className="community-tiles-flex">{segmentTiles}</div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    segments: state.segments.byId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchSegments,
    },
    dispatch
  );
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumSegmentList): any);
