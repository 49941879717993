// @flow
import * as React from 'react';
import WelcomeBannerPersona from './WelcomeBannerPersona';
import Link from '../Link';
import ReactHtmlParser from 'html-react-parser';
// import type { StaticCommunity } from '../../types/communities';
import { Container } from 'semantic-ui-react';
import type { WelcomeBannerType } from '../../types';
import { getStaticPersonas } from '../../helpers/personas';

type Props = {
  row: WelcomeBannerType,
};

const WelcomeBanner = ({ row }: Props): React.Element<'div'> => {
  // fetch hardcoded community data
  const communities = getStaticPersonas();

  // format header title: searches for | character splits with to next line after 
  const helixIndex = row?.header?.toLowerCase().lastIndexOf(ReactHtmlParser('&verbar;'));
  const headerPart1 = row?.header?.slice(
    0,
    helixIndex === -1 ? row?.header?.length : helixIndex
  );
  const headerPart2 = row?.header?.slice(
    helixIndex === -1 ? row?.header?.length : helixIndex
  );

  const headerPart2RemovePipe = headerPart2?.slice(2);

  // rewrites the personas listing page slug as a child of the current page - helps handle the product page that renders this being used here and in the helix marketing site
  const href =
    row?.button?.url === '/personas'
      ? window.location.pathname + '/personas'
      : row?.button?.url;

  return (
    <div className="welcome">
      <Container>
        <div className="welcome__banner">
          {communities.map((community) => (
            <WelcomeBannerPersona
              key={community.id}
              id={community.id}
              name={community.name}
              img={community.img}
              colour={community.colour}
            />
          ))}
          <div className="banner__persona banner__persona--blank blank--1">
            <div className="persona__content"></div>
          </div>
          <div className="banner__persona banner__persona--blank blank--2">
            <div className="persona__content"></div>
          </div>
        </div>
        <div className="banner__content-wrapper">
          <div className="banner__top-text">{headerPart1}</div>
          <div className="banner__content">
            <h1 className="content__header">
              <span className="banner__part-one">{headerPart1}{" "}</span>
              {headerPart2RemovePipe && headerPart2RemovePipe}
            </h1>
            <p>
              {ReactHtmlParser(row?.intro)}
              {row?.button && (
                <Link
                  url={href}
                  className="button"
                  target={row?.button.target}
                >
                  {row?.button.title}
                </Link>
              )}
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WelcomeBanner;
